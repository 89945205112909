<template>
  <div class="container">
    <div class="d-flex flex-column align-items-center mb-4 mt-5">
      <div class="yellow-line"></div>
      <div class="subheader fw-bold fs-3 text-uppercase text-center mb-2">
        Your Exclusive Content Hub<br />资料专区
      </div>
      <div class="yellow-line"></div>
    </div>
    <div class="container mb-3 mb-md-5" v-if="gettingLinks">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center justify-content-center">
            <div class="spinner-border text-danger" role="status"></div>
            <div class="ms-3">
              <strong>Loading your Content Hub...</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="container mb-3 mb-md-5"
      v-if="!gettingLinks"
    >
      <div class="row services-container mt-3 mb-3">
        <div class="col-12 mb-3 mb-md-0">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-6">
              <a href='https://register.jhmetaphysics.com' target='_blank'>
              <img src="../assets/2025-seminar-2.jpg"
              class="img-fluid" />
              </a>
            </div>
          </div>
          <div class="card mt-2">
            <div class="card-body">
              <div
                class="row services-container border-bottom pt-3 pb-3"
                v-if="seminarEnrolment"
              >
                <div class="col-12 col-md-4 mb-3 mb-md-0">
                  <img src="../assets/2025-seminar-2.jpg" class="img-fluid" />
                </div>
                <div class="col-12 col-md-8 mb-3 mb-md-0">
                  <div class="row">
                    <div class="col-12">
                      Seminar handouts<br />大会讲义
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mt-3">
                      <button
                            type="button"
                            class="btn btn-warning"
                            @click="viewHandouts()"
                          >
                            <i class="far fa-eye"></i> View
                          </button>&nbsp;
                      <button
                            type="button"
                            class="btn btn-warning"
                            @click="downloadFile('handouts.pdf', 'bazi')"
                            v-bind:disabled="handoutsDownloadInProgress"
                          >
                            <i
                              class="fas fa-file-download"
                              v-if="!handoutsDownloadInProgress"
                            ></i
                            ><i
                              class="fas fa-spinner fa-spin"
                              v-if="handoutsDownloadInProgress"
                            ></i>
                            Download PDF
                          </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row services-container border-bottom pt-3 pb-3"
                v-if="seminarQRCode"
              >
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                  <img src="../assets/2025-seminar-2.jpg" class="img-fluid" />
                </div>
                <div class="col-12 col-md-8 mb-3 mb-md-0">
                  <div class="row">
                    <div class="col-12">
                      2025年 乙巳年风水大会 入门票 E-Ticket
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mt-3">
                      <qrcode-vue :value="seminarQRCode" size="120" level="M" /><br/>
                      <h3>{{ seminarConfirmationCode }}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div
                class="row services-container border-bottom pt-3 pb-3"
                v-if="seminarEnrolment"
              >
                <div class="col-12 col-md-8 mb-3 mb-md-0">
                  <em
                    >View in full screen for best experience
                    全屏观看效果最佳</em
                  ><br/>
                  <strong>2023癸卯年 十二生肖运势+玄空飞星開運攻略<br/></strong>Replay of Dec 30, 2022 07:30 PM webinar
                  <div class="ratio ratio-16x9 mt-3">
                    <iframe
                      :src='`https://player.vimeo.com/video/785239530`'
                      style="border: none; overflow: hidden"
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="encrypted-media; picture-in-picture;"
                    ></iframe>
                  </div>
                </div>
              </div> -->
              <div
                  class="row services-container border-bottom pt-3 pb-3"
              >
                <div class="col-12 col-md-4 mb-3 mb-md-0">
                  <img src="../assets/calendar.png" class="img-fluid" />
                </div>
                <div class="col-12 col-md-8 mb-3 mb-md-0">
                  <div class="row">
                    <div class="col-12">
                      2025 Personal Metaphysics E-Calendar<br />2025年 个人电子玄学实战日历
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mt-3">
                      <!-- start of user button -->
                      <div v-for="person in calendar" :key="person[0].OrderId">
                        <div
                            class="btn-group mb-2"
                            role="group"
                            aria-label="Basic example"
                        >
                          <button
                              type="button"
                              class="btn btn-warning active disabled"
                          >
                            <i class="fas fa-user"></i>&nbsp;&nbsp;{{
                              person[0].Name
                            }}
                            -
                            {{
                              bdString(person[0].Birthday)
                              /*
                              chart.Birthday.split("T")[1] == "99:99"
                                ? bdString(chart.Birthday.split("T")[0])
                                : bdString(chart.Birthday)
                              */
                            }}
                          </button>
                          <button
                              type="button"
                              class="btn btn-warning dropdown-toggle"
                              data-bs-toggle="dropdown"
                          >
                            <i class="fas fa-calendar-alt"></i> Calendar
                          </button>
                          <ul class="dropdown-menu">
                            <li v-for="month in person" :key="month.SK"><a class="dropdown-item" @click='viewCalendar(month.S3ObjectKey, person)'>{{ calendarMonthDisplay(month.Product.split('#')[1]) }}</a></li>
                          </ul>
                        </div>
                      </div>
                      <!-- end of user button -->
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 mt-1">
                      <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm"
                          data-bs-toggle="modal"
                          data-bs-target="#calendarModal"
                      >
                        <i class="fas fa-user-plus"></i> Buy another calendar
                        增购玄学日历
                        <!--                         <span
                                                  class="badge bg-success"
                                                  v-if="customer.Country != 'Malaysia'"
                                                  >SG$ 69</span
                                                >
                                                <span
                                                  class="badge bg-success"
                                                  v-if="customer.Country == 'Malaysia'"
                                                  >RM$ 208</span
                                                > -->
                      </button>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-12 mt-1">
                      How to use e-calendar 如何使用个人电子玄学实战日历<br/>
                      <div class="ratio ratio-16x9 mt-3">
                        <iframe
                            :src='`https://player.vimeo.com/video/787073703`'
                            style="border: none; overflow: hidden"
                            scrolling="no"
                            frameborder="0"
                            allowfullscreen="true"
                            allow="encrypted-media; picture-in-picture;"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
<!--              <div-->
<!--                class="row services-container border-bottom pt-3 pb-3"-->
<!--              >-->
<!--                <div class="col-12 col-md-4 mb-3 mb-md-0">-->
<!--                  <img src="../assets/calendar.png" class="img-fluid" />-->
<!--                </div>-->
<!--                <div class="col-12 col-md-8 mb-3 mb-md-0">-->
<!--                  <div class="row">-->
<!--                    <div class="col-12">-->
<!--                      2024 Personal Metaphysics E-Calendar<br />2024年 个人电子玄学实战日历-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="row">-->
<!--                    <div class="col-12 mt-3">-->
<!--                      &lt;!&ndash; start of user button &ndash;&gt;-->
<!--                      <div v-for="person in calendarLastYear" :key="person[0].OrderId">-->
<!--                        <div-->
<!--                          class="btn-group mb-2"-->
<!--                          role="group"-->
<!--                          aria-label="Basic example"-->
<!--                        >-->
<!--                          <button-->
<!--                            type="button"-->
<!--                            class="btn btn-warning active disabled"-->
<!--                          >-->
<!--                            <i class="fas fa-user"></i>&nbsp;&nbsp;{{-->
<!--                              person[0].Name-->
<!--                            }}-->
<!--                            - -->
<!--                            {{-->
<!--                              bdString(person[0].Birthday)-->
<!--                              /*-->
<!--                              chart.Birthday.split("T")[1] == "99:99"-->
<!--                                ? bdString(chart.Birthday.split("T")[0])-->
<!--                                : bdString(chart.Birthday)-->
<!--                              */-->
<!--                            }}-->
<!--                          </button>-->
<!--                          <button-->
<!--                            type="button"-->
<!--                            class="btn btn-warning dropdown-toggle"-->
<!--                            data-bs-toggle="dropdown"-->
<!--                          >-->
<!--                            <i class="fas fa-calendar-alt"></i> Calendar-->
<!--                          </button>-->
<!--                          <ul class="dropdown-menu">-->
<!--                            <li v-for="month in person" :key="month.SK"><a class="dropdown-item" @click='viewCalendar(month.S3ObjectKey, person)'>{{ calendarMonthDisplayLastYear(month.Product.split('#')[1]) }}</a></li>-->
<!--                          </ul>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      &lt;!&ndash; end of user button &ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->
<!--&lt;!&ndash;                  <div class="row mt-3">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="col-12 mt-1">&ndash;&gt;-->
<!--&lt;!&ndash;                      <button&ndash;&gt;-->
<!--&lt;!&ndash;                        type="button"&ndash;&gt;-->
<!--&lt;!&ndash;                        class="btn btn-outline-secondary btn-sm"&ndash;&gt;-->
<!--&lt;!&ndash;                        data-bs-toggle="modal"&ndash;&gt;-->
<!--&lt;!&ndash;                        data-bs-target="#calendarModal"&ndash;&gt;-->
<!--&lt;!&ndash;                      >&ndash;&gt;-->
<!--&lt;!&ndash;                        <i class="fas fa-user-plus"></i> Buy another calendar&ndash;&gt;-->
<!--&lt;!&ndash;                        增购玄学日历&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                         <span&ndash;&gt;-->
<!--&lt;!&ndash;                          class="badge bg-success"&ndash;&gt;-->
<!--&lt;!&ndash;                          v-if="customer.Country != 'Malaysia'"&ndash;&gt;-->
<!--&lt;!&ndash;                          >SG$ 69</span&ndash;&gt;-->
<!--&lt;!&ndash;                        >&ndash;&gt;-->
<!--&lt;!&ndash;                        <span&ndash;&gt;-->
<!--&lt;!&ndash;                          class="badge bg-success"&ndash;&gt;-->
<!--&lt;!&ndash;                          v-if="customer.Country == 'Malaysia'"&ndash;&gt;-->
<!--&lt;!&ndash;                          >RM$ 208</span&ndash;&gt;-->
<!--&lt;!&ndash;                        > &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                      </button>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div class="row mt-5">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="col-12 mt-1">&ndash;&gt;-->
<!--&lt;!&ndash;                      How to use e-calendar 如何使用个人电子玄学实战日历<br/>&ndash;&gt;-->
<!--&lt;!&ndash;                      <div class="ratio ratio-16x9 mt-3">&ndash;&gt;-->
<!--&lt;!&ndash;                        <iframe&ndash;&gt;-->
<!--&lt;!&ndash;                          :src='`https://player.vimeo.com/video/787073703`'&ndash;&gt;-->
<!--&lt;!&ndash;                          style="border: none; overflow: hidden"&ndash;&gt;-->
<!--&lt;!&ndash;                          scrolling="no"&ndash;&gt;-->
<!--&lt;!&ndash;                          frameborder="0"&ndash;&gt;-->
<!--&lt;!&ndash;                          allowfullscreen="true"&ndash;&gt;-->
<!--&lt;!&ndash;                          allow="encrypted-media; picture-in-picture;"&ndash;&gt;-->
<!--&lt;!&ndash;                        ></iframe>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
              <!-- <div
                class="row services-container border-bottom pt-3 pb-3"
              >
                <div class="col-12 col-md-4 mb-3 mb-md-0">
                  <img src="../assets/calendar.png" class="img-fluid" />
                </div>
                <div class="col-12 col-md-8 mb-3 mb-md-0">
                  <div class="row">
                    <div class="col-12">
                      2023 Personal Metaphysics E-Calendar<br />2023年 个人电子玄学实战日历
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mt-3"> -->
                      <!-- start of user button -->
                      <!-- <div v-for="person in calendarLastYear" :key="person[0].OrderId">
                        <div
                          class="btn-group mb-2"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            class="btn btn-warning active disabled"
                          >
                            <i class="fas fa-user"></i>&nbsp;&nbsp;{{
                              person[0].Name
                            }}
                            -
                            {{
                              bdString(person[0].Birthday)
                              /*
                              chart.Birthday.split("T")[1] == "99:99"
                                ? bdString(chart.Birthday.split("T")[0])
                                : bdString(chart.Birthday)
                              */
                            }}
                          </button>
                          <button
                            type="button"
                            class="btn btn-warning dropdown-toggle"
                            data-bs-toggle="dropdown"
                          >
                            <i class="fas fa-calendar-alt"></i> Calendar
                          </button>
                          <ul class="dropdown-menu">
                            <li v-for="month in person" :key="month.SK"><a class="dropdown-item" @click='viewCalendar(month.S3ObjectKey, person)'>{{ calendarMonthDisplayLastYear(month.Product.split('#')[1]) }}</a></li>
                          </ul>
                        </div>
                      </div> -->
                      <!-- end of user button -->
                    <!-- </div>
                  </div>
                </div>
              </div> -->
              <div
                class="row services-container border-bottom pt-3 pb-3"
                v-if="showBaziChart"
              >
                <div class="col-12 col-md-4 mb-3 mb-md-0">
                  <img src="../assets/bazi.png" class="img-fluid" />
                </div>
                <div class="col-12 col-md-8 mb-3 mb-md-0">
                  <div class="row">
                    <div class="col-12">
                      Bazi Chart (2025 edition)<br />八字个人蓝图（2025年版本）
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mt-3">
                      <!-- start of user button -->
                      <div v-for="chart in baziCharts" :key="chart.OrderId">
                        <div
                          class="btn-group mb-2"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            class="btn btn-warning active disabled"
                          >
                            <i class="fas fa-user"></i>&nbsp;&nbsp;{{
                              chart.Name
                            }}
                            -
                            {{
                              bdString(chart.Birthday)
                              /*
                              chart.Birthday.split("T")[1] == "99:99"
                                ? bdString(chart.Birthday.split("T")[0])
                                : bdString(chart.Birthday)
                              */
                            }}
                          </button>
                          <button
                            type="button"
                            class="btn btn-warning"
                            @click="viewFile(chart.S3ObjectKey)"
                          >
                            <i class="far fa-eye"></i> View
                          </button>
                          <button
                            type="button"
                            class="btn btn-warning"
                            @click="downloadFile(chart, 'bazi')"
                            v-bind:disabled="chart.downloadInProgress"
                          >
                            <i
                              class="fas fa-file-download"
                              v-if="!chart.downloadInProgress"
                            ></i
                            ><i
                              class="fas fa-spinner fa-spin"
                              v-if="chart.downloadInProgress"
                            ></i>
                            Download PDF
                          </button>
                        </div>
                      </div>
                      <!-- end of user button -->
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 mt-1">
                      <button
                        type="button"
                        class="btn btn-outline-secondary btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <i class="fas fa-user-plus"></i> Buy another chart
                        增购八字图
                        <span
                          class="badge bg-success"
                          >SG$ 19.90</span
                        >
<!--                        <span-->
<!--                          class="badge bg-success"-->
<!--                          v-if="customer.Country == 'Malaysia'"-->
<!--                          >RM$ 59</span-->
<!--                        >-->
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="row services-container pt-3" v-if='energyCharts.length > 0'>
                <div class="col-12 col-md-4 mb-3 mb-md-0">
                  <img src="../assets/energy.png" class="img-fluid" />
                </div>
                <div class="col-12 col-md-8 mb-3 mb-md-0">
                  <div class="row">
                    <div class="col-12">
                      Individual Energy chart<br />个人运气能量表
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mt-3">
                      <div v-for="chart in energyCharts" :key="chart.OrderId">
                        <div
                          class="btn-group mb-2"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            class="btn btn-warning active disabled"
                          >
                            <i class="fas fa-user"></i>&nbsp;&nbsp;{{
                              chart.Name
                            }}
                            -
                            {{
                              bdString(chart.Birthday)
                              /*
                              chart.Birthday.split("T")[1] == "99:99"
                                ? bdString(chart.Birthday.split("T")[0])
                                : bdString(chart.Birthday)
                              */
                            }}
                          </button>
                        </div>
                        <img class="img-fluid" :src='chart.url' />
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="row services-container border-bottom pt-3 pb-3" v-if='hasQimen2022.length > 0 || hasQimen2023.length > 0 || hasQimen2024.length > 0'>
                <div class="col-12 mb-3 mb-md-0">
<!--                   <em
                    >View in full screen for best experience
                    全屏观看效果最佳</em
                  ><br/> -->
                  <a type="button" class="btn btn-warning ms-auto" href="#/qimen/predict"
        ><i class="fas fa-binoculars"></i> 奇门预测软件</a
      >&nbsp;<a type="button" class="btn btn-warning ms-auto" href="#/qimen/strategy"
        ><i class="fas fa-chess"></i> 奇门运筹软件</a
      >
      &nbsp;<a type="button" class="btn btn-warning ms-auto" href="#/qimen/wealth"
        ><i class="fas fa-briefcase"></i> 奇门财商软件</a
      >
                  <!-- <template v-for="replay in qimenReplays" :key="replay.OrderId">
                  <div class="ratio ratio-16x9 mt-3">
                    <iframe
                      :src='`https://player.vimeo.com/video/${replay.S3ObjectKey}`'
                      style="border: none; overflow: hidden"
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="encrypted-media; picture-in-picture;"
                    ></iframe>
                  </div>
                  </template>
                  <br/>
                  <a type="button" class="btn btn-warning ms-auto" href="#/qimen/predict"
        ><i class="fas fa-binoculars"></i> 奇门预测软件</a
      >&nbsp;<a type="button" class="btn btn-warning ms-auto" href="#/qimen/strategy"
        ><i class="fas fa-chess"></i> 奇门运筹软件</a
      > -->
                </div>
              </div>
              <div class="row services-container border-bottom pt-3 pb-3" v-if='qimenReplays.length > 0'>
                <div class="col-12 mb-3 mb-md-0">
                   <em
                    >View in full screen for best experience
                    全屏观看效果最佳</em
                  ><br/>
                  <template v-for="replay in qimenReplays" :key="replay.OrderId">
                  <div class="ratio ratio-16x9 mt-3">
                    <iframe
                      :src='`https://player.vimeo.com/video/${replay.S3ObjectKey}`'
                      style="border: none; overflow: hidden"
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="encrypted-media; picture-in-picture;"
                    ></iframe>
                  </div>
                  </template>
                </div>
              </div>
              <div class="row services-container border-bottom pt-3 pb-3" v-if='qimen2023TurnaroundReplay.length > 0'>
                <div class="col-12 mb-3 mb-md-0">
                   <em
                    >View in full screen for best experience
                    全屏观看效果最佳</em
                  ><br/>
                  <template v-for="replay in qimen2023TurnaroundReplay" :key="replay.OrderId">
                  <div class="ratio ratio-16x9 mt-3">
                    <iframe
                      :src='`https://player.vimeo.com/video/${replay.S3ObjectKey}`'
                      style="border: none; overflow: hidden"
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="encrypted-media; picture-in-picture;"
                    ></iframe>
                  </div>
                  </template>
                </div>
              </div>
              <div class="row services-container border-bottom pt-3 pb-3" v-if='qimen2023Replays.length > 0'>
                <div class="col-12 mb-3 mb-md-0">
                   <em
                    >View in full screen for best experience
                    全屏观看效果最佳</em
                  ><br/>
                  <template v-for="replay in qimen2023Replays" :key="replay.OrderId">
                  <div class="ratio ratio-16x9 mt-3">
                    <iframe
                      :src='`https://player.vimeo.com/video/${replay.S3ObjectKey}`'
                      style="border: none; overflow: hidden"
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="encrypted-media; picture-in-picture;"
                    ></iframe>
                  </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-xl modal-fullscreen-xl-down
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Buy Premium Bazi Chart 增购八字个人蓝图
            <span class="badge bg-success"
              >SG$ 19.90</span
            >
<!--            <span class="badge bg-success" v-if="customer.Country == 'Malaysia'"-->
<!--              >RM$ 59</span-->
<!--            >-->
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="clearData"
            v-show="!processingModal"
          ></button>
        </div>
        <div class="modal-body" v-show="!processingModal">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <h6>Important Notes</h6>
                <ul>
                  <li>This is a non-refundable purchase.</li>
                  <li>
                    It is not possible to amend the details so please check
                    carefully before submitting!
                  </li>
                  <li>
                    If time of birth is unknown, some features of the chart will
                    not be available.
                  </li>
                  <li>
                    The chart will be ready in about 10 minutes once payment is
                    processed.
                  </li>
                </ul>
              </div>
              <div class="col-sm-12 col-md-6">
                <h6>购前须知</h6>
                <ul>
                  <li>一旦付款不可退款</li>
                  <li>付款后不能更改表格里的信息，请再三确认后才付款！</li>
                  <li>若不提供出生时间，八字图将不会出现些许信息</li>
                  <li>付款后能在10分钟后下载八字图</li>
                </ul>
              </div>
            </div>
          </div>
          <form class="border px-3 py-3" novalidate>
            <div class="row mb-3">
              <label for="name" class="col-sm-2 col-form-label pt-0"
                >Name<br />姓名</label
              >
              <div class="col-sm-10">
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  v-model="name"
                  :class="[!name && turnOnValidators ? 'is-invalid' : '']"
                />
              </div>
            </div>
            <div class="row mb-3">
              <label for="name" class="col-sm-2 col-form-label pt-0"
                >Gender<br />性别</label
              >
              <div class="col-sm-10">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="gender"
                    id="genderMale"
                    v-model="gender"
                    value="m"
                    :class="[!gender && turnOnValidators ? 'is-invalid' : '']"
                  />
                  <label class="form-check-label" for="genderMale">
                    Male 男
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="gender"
                    id="genderFemale"
                    v-model="gender"
                    value="f"
                    :class="[!gender && turnOnValidators ? 'is-invalid' : '']"
                  />
                  <label class="form-check-label" for="genderFemale">
                    Female 女
                  </label>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label pt-0"
                >Date of Birth (Solar)<br />出生日期（阳历）</label
              >
              <div class="col-sm-3">
                <select
                  class="form-select"
                  v-model="day"
                  :class="[
                    !day && turnOnValidators ? 'is-invalid' : '',
                    validDate ? '' : 'is-invalid',
                  ]"
                >
                  <option selected value="null">Select day 日</option>
                  <option
                    v-for="day in days"
                    :value="day.label"
                    :key="day.label"
                  >
                    {{ day.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <select
                  class="form-select"
                  v-model="month"
                  :class="[
                    !month && turnOnValidators ? 'is-invalid' : '',
                    validDate ? '' : 'is-invalid',
                  ]"
                >
                  <option selected value="null">Select month 月</option>
                  <option
                    v-for="month in months"
                    :value="month.value"
                    :key="month.value"
                  >
                    {{ month.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-4">
                <select
                  class="form-select"
                  v-model="year"
                  :class="[
                    !year && turnOnValidators ? 'is-invalid' : '',
                    validDate ? '' : 'is-invalid',
                  ]"
                >
                  <option selected value="null">Select year 年</option>
                  <option
                    v-for="year in years"
                    :value="year.label"
                    :key="year.label"
                  >
                    {{ year.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label pt-0"
                >Time of Birth<br />出生时间</label
              >
              <div class="col-sm-3">
                <select
                  class="form-select"
                  v-model="hour"
                  v-bind:disabled="noTime"
                  :class="[
                    !hour && turnOnValidators && !noTime ? 'is-invalid' : '',
                  ]"
                >
                  <option selected value="null">Select hour 时</option>
                  <option
                    v-for="hour in hours"
                    :value="hour.label"
                    :key="hour.label"
                  >
                    {{ hour.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <select
                  class="form-select"
                  v-model="minute"
                  v-bind:disabled="noTime"
                  :class="[
                    !minute && turnOnValidators && !noTime ? 'is-invalid' : '',
                  ]"
                >
                  <option selected value="null">Select minute 分</option>
                  <option
                    v-for="minute in minutes"
                    :value="minute.label"
                    :key="minute.value"
                  >
                    {{ minute.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-4">
                <select
                  class="form-select"
                  v-model="selectedTimeOfDay"
                  v-bind:disabled="noTime"
                  :class="[
                    !selectedTimeOfDay && turnOnValidators && !noTime
                      ? 'is-invalid'
                      : '',
                  ]"
                >
                  <option selected value="null">Select AM/PM</option>
                  <option
                    v-for="t in timeOfDay"
                    :value="t.label"
                    :key="t.label"
                  >
                    {{ t.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label">&nbsp;</label>
              <div class="col-sm-10">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="unknownTime"
                    v-model="noTime"
                  />
                  <label class="form-check-label pt-0" for="unknownTime">
                    Unknown time of birth 无法提供出生时间
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-body" v-show="processingModal">
          <div class="d-flex align-items-center">
            <div class="spinner-border text-danger" role="status"></div>
            <div class="ms-3">
              <strong>Redirecting to Stripe Checkout...</strong>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-show="!processingModal">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="clearData"
          >
            Cancel
          </button>
          <button type="button" class="btn btn-primary" @click="buyChart">
            Buy chart
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="calendarModal"
    tabindex="-1"
    aria-labelledby="calendarModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-xl modal-fullscreen-xl-down
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="calendarModalLabel">
            Buy Metaphysics Calendar 2025 增购玄学实战日历 2025
            <span class="badge bg-success"
              >SG$ 119</span
            >
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="clearData"
            v-show="!processingModal"
          ></button>
        </div>
        <div class="modal-body" v-show="!processingModal">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <h6>Important Notes</h6>
                <ul>
                  <li>This is a non-refundable purchase for Year 2025 metaphysics calendar (Jan - Dec, 2025).</li>
                  <li>
                    It is not possible to amend the details so please check
                    carefully before submitting!
                  </li>
                  <li>
                    If time of birth is unknown, some features of the chart will
                    not be available and will not be accurate.
                  </li>
                  <li>
                    The charts will be ready in about 10 minutes once payment is
                    processed.
                  </li>
                </ul>
              </div>
              <div class="col-sm-12 col-md-6">
                <h6>购前须知</h6>
                <ul>
                  <li>一旦付款不可退款（2025 玄学日历，阳历一月至十二月）</li>
                  <li>付款后不能更改表格里的信息，请再三确认后才付款！</li>
                  <li>若不提供出生时间，日历将不会出现些许信息</li>
                  <li>付款后能在10分钟后下载日历</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="container pb-3" v-if="calendarEligibleForDiscount.length > 0">
            <div class="row">
              <div class="col-12">
                <h6>2025: Special discount for seminar attendees 大会参加者优惠价格 <span class="badge bg-success"
              >SG$ 69</span
            ></h6>
                <ul>
                  <li>Discount is only valid for the person's details submitted at registration.</li>
                  <li>优惠仅供大会报名时的资料。</li>
                </ul>         
              </div>              
            </div>
          </div>
          <form class="border px-3 py-3" novalidate>
            <div class="row mb-3">
              <label for="name" class="col-sm-2 col-form-label pt-0"
                >Name<br />姓名</label
              >
              <div class="col-sm-10">
                <select
                  class="form-select mb-2"
                  v-model="selectedDiscountName"
                  v-if="calendarEligibleForDiscount.length > 0"
                >
                  <option selected value="spacer1">Select name 请选择名字</option>
                  <option value="spacer2">--- Seminar attendees 大会出席者 ---</option>
                  <option
                    v-for="person in calendarEligibleForDiscount"
                    :value="person"
                    :key="person.OrderId"
                  >
                    {{ person.Name }} ({{ bdString(person.Birthday) }})
                  </option>
                  <option value="spacer3">---</option>
                  <option value="nodiscount">Person not listed above (no discount) 其他（没有优惠）</option>
                </select>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  v-model="name"
                  v-if="selectedDiscountName === 'nodiscount' || calendarEligibleForDiscount.length === 0"
                  :class="[!name && turnOnValidators ? 'is-invalid' : '']"
                />
              </div>
            </div>
            <template v-if="!((selectedDiscountName == 'spacer1') || (selectedDiscountName == 'spacer2') || (selectedDiscountName == 'spacer3')) || calendarEligibleForDiscount.length === 0">
            <div class="row mb-3">
              <label for="name" class="col-sm-2 col-form-label pt-0"
                >Gender<br />性别</label
              >
              <div class="col-sm-10">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="gender"
                    id="genderMale"
                    v-model="gender"
                    value="m"
                    :class="[!gender && turnOnValidators ? 'is-invalid' : '']"
                    :disabled="disableFields"
                  />
                  <label class="form-check-label" for="genderMale">
                    Male 男
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="gender"
                    id="genderFemale"
                    v-model="gender"
                    value="f"
                    :class="[!gender && turnOnValidators ? 'is-invalid' : '']"
                    :disabled="disableFields"
                  />
                  <label class="form-check-label" for="genderFemale">
                    Female 女
                  </label>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label pt-0"
                >Date of Birth (Solar)<br />出生日期（阳历）</label
              >
              <div class="col-sm-3">
                <select
                  class="form-select"
                  v-model="day"
                  :class="[
                    !day && turnOnValidators ? 'is-invalid' : '',
                    validDate ? '' : 'is-invalid',
                  ]"
                  :disabled="disableFields"
                >
                  <option selected value="null">Select day 日</option>
                  <option
                    v-for="day in days"
                    :value="day.label"
                    :key="day.label"
                  >
                    {{ day.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <select
                  class="form-select"
                  v-model="month"
                  :class="[
                    !month && turnOnValidators ? 'is-invalid' : '',
                    validDate ? '' : 'is-invalid',
                  ]"
                  :disabled="disableFields"
                >
                  <option selected value="null">Select month 月</option>
                  <option
                    v-for="month in months"
                    :value="month.value"
                    :key="month.value"
                  >
                    {{ month.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-4">
                <select
                  class="form-select"
                  v-model="year"
                  :class="[
                    !year && turnOnValidators ? 'is-invalid' : '',
                    validDate ? '' : 'is-invalid',
                  ]"
                  :disabled="disableFields"
                >
                  <option selected value="null">Select year 年</option>
                  <option
                    v-for="year in years"
                    :value="year.label"
                    :key="year.label"
                  >
                    {{ year.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label pt-0"
                >Time of Birth<br />出生时间</label
              >
              <div class="col-sm-3">
                <select
                  class="form-select"
                  v-model="hour"
                  v-bind:disabled="noTime || disableFields"
                  :class="[
                    !hour && turnOnValidators && !noTime ? 'is-invalid' : '',
                  ]"
                >
                  <option selected value="null">Select hour 时</option>
                  <option
                    v-for="hour in hours"
                    :value="hour.label"
                    :key="hour.label"
                  >
                    {{ hour.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <select
                  class="form-select"
                  v-model="minute"
                  v-bind:disabled="noTime || disableFields"
                  :class="[
                    !minute && turnOnValidators && !noTime ? 'is-invalid' : '',
                  ]"
                >
                  <option selected value="null">Select minute 分</option>
                  <option
                    v-for="minute in minutes"
                    :value="minute.label"
                    :key="minute.value"
                  >
                    {{ minute.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-4">
                <select
                  class="form-select"
                  v-model="selectedTimeOfDay"
                  v-bind:disabled="noTime || disableFields"
                  :class="[
                    !selectedTimeOfDay && turnOnValidators && !noTime
                      ? 'is-invalid'
                      : '',
                  ]"                  
                >
                  <option selected value="null">Select AM/PM</option>
                  <option
                    v-for="t in timeOfDay"
                    :value="t.label"
                    :key="t.label"
                  >
                    {{ t.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label">&nbsp;</label>
              <div class="col-sm-10">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="unknownTime"
                    v-model="noTime"
                    :disabled="disableFields"
                  />
                  <label class="form-check-label pt-0" for="unknownTime">
                    Unknown time of birth 无法提供出生时间
                  </label>
                </div>
              </div>
            </div>
            </template>
          </form>
        </div>
        <div class="modal-body" v-show="processingModal">
          <div class="d-flex align-items-center">
            <div class="spinner-border text-danger" role="status"></div>
            <div class="ms-3">
              <strong>Redirecting to Stripe Checkout...</strong>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-show="!processingModal">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="clearData"
          >
            Cancel
          </button>
          <button type="button" class="btn btn-primary" @click="buyCalendar">
            Buy calendar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { DateTime } from "luxon";
import { Auth, API } from "aws-amplify";
import { find, filter, map, startsWith, sortBy, groupBy, differenceWith } from "lodash";
import { saveAs } from "file-saver";
import QrcodeVue from 'qrcode.vue'
const inclusiveRange = (start, end, step) => {
  return Array.from(
    Array.from(Array(Math.ceil((end - start + 1) / step)).keys()),
    (x) => {
      return { label: start + x * step };
    }
  );
};
export default {
  name: "Home",
  components: {
    QrcodeVue
  },
  data: () => ({
    days: Array.from(Array(31).keys(), (n) => {
      return { label: n + 1 };
    }),
    months: [
      { label: "Jan (1)", value: 1 },
      { label: "Feb (2)", value: 2 },
      { label: "Mar (3)", value: 3 },
      { label: "Apr (4)", value: 4 },
      { label: "May (5)", value: 5 },
      { label: "Jun (6)", value: 6 },
      { label: "Jul (7)", value: 7 },
      { label: "Aug (8)", value: 8 },
      { label: "Sep (9)", value: 9 },
      { label: "Oct (10)", value: 10 },
      { label: "Nov (11)", value: 11 },
      { label: "Dec (12)", value: 12 },
    ],
    years: inclusiveRange(2025, 1918, -1),
    hours: Array.from(Array(12).keys(), (n) => {
      return { label: n + 1 };
    }),
    minutes: Array.from(Array(60).keys(), (n) => {
      return { label: String(n).padStart(2, "0") };
    }),
    timeOfDay: Array.from(["AM", "PM"], (n) => {
      return { label: n };
    }),
    name: null,
    day: null,
    month: null,
    year: null,
    hour: null,
    minute: null,
    selectedTimeOfDay: null,
    noTime: false,
    gender: null,
    turnOnValidators: false,
    processingModal: false,
    gettingLinks: false,
    allLinks: null,
    showBaziChart: true,
    handoutsDownloadInProgress: false,
    energyCharts: [],
    selectedDiscountName: 'spacer1',
    disableFields: false,
  }),
  watch: {
    noTime(newState) {
      if (newState) {
        this.hour = null;
        this.minute = null;
        this.selectedTimeOfDay = null;
      }
    },
    selectedDiscountName(selectedPerson) {
      if (selectedPerson) {
        this.hour = null;
        this.minute = null;
        this.selectedTimeOfDay = null;
        this.day = null;
        this.month = null;
        this.year = null;
        this.gender = null;
        this.noTime = false;
        this.name = null;

        if (selectedPerson.Name) {
          this.disableFields = true;
          this.name = selectedPerson.Name;

          let bd;
          if (selectedPerson.Birthday.split("T")[1] == '99:99') {
            bd = DateTime.fromISO(selectedPerson.Birthday.split("T")[0])
            this.noTime = true;
          } else {
            bd = DateTime.fromISO(selectedPerson.Birthday)
            this.hour = bd.toFormat("h")
            this.minute = bd.toFormat("mm")
            this.selectedTimeOfDay = bd.toFormat("a")
          }

          this.day = bd.day;
          this.month = bd.month;
          this.year = bd.year;
          this.gender = selectedPerson.Gender
        } else {
          this.disableFields = false;
        }
      } else {
        this.disableFields = false;
      }
    }
  },
  computed: {
    validDate() {
      if (this.year && this.month && this.day) {
        return DateTime.fromObject({
          year: this.year,
          month: this.month,
          day: this.day,
        }).isValid;
      }
      return true;
    },
    customer() {
      const cust = find(this.allLinks, {
        PK: `CUSTOMER#${this.$store.state.userEmail}`,
        SK: `CUSTOMER#${this.$store.state.userEmail}`,
      });
      if (cust) {
        return cust;
      } else {
        return { Country: "" };
      }
    },
    baziCharts() {
      let charts = filter(this.allLinks, {
        Product: "BaziChart2025",
        PaymentStatus: "paid",
      });
      charts = map(charts, (chart) => {
        chart.downloadInProgress = false;
        return chart;
      });
      return charts;
    },
    calendarLastYear() {
      let charts = filter(this.allLinks, item => {
        return startsWith(item.Product, "Calendar2024#") && item.PaymentStatus == 'paid'
      });
      return groupBy(sortBy(charts, e => parseInt(e.Product.split('#')[1])), 'Name');
    },
    calendar() {
      let charts = filter(this.allLinks, item => {
        return startsWith(item.Product, "Calendar2025#") && item.PaymentStatus == 'paid'
      });
      return groupBy(sortBy(charts, e => parseInt(e.Product.split('#')[1])), 'Name');
    },
    calendarEligibleForDiscount() {
      let seminar = filter(this.allLinks, item => {
        return startsWith(item.Product, "Seminar2025") && item.PaymentStatus == 'paid'
      });
      
      // calendar2024 will store a field to determine which discount has been applied (from seminar)
      let charts = filter(this.allLinks, item => {
        return startsWith(item.Product, "Calendar2025") && item.PaymentStatus == 'paid'
      });
      
      // CalendarDiscountCodeId equals seminar order ID
      return differenceWith(seminar, charts, (arrVal, othVal) => {
        return arrVal['OrderId'] == othVal['EventOrderId']
      })
    },
    seminarEnrolment() {
      if (find(this.allLinks, {
        Product: "Seminar2025",
        PaymentStatus: "paid",
      })) {
        return 'full'
      } else if (find(this.allLinks, {
        Product: "SeminarFOC2025",
        PaymentStatus: "paid",
      })) {
        return 'foc'
      } else {
        return false;
      }
    },
    seminarQRCode() {
      const findResult = find(this.allLinks, {
        Product: "Seminar2025",
        PaymentStatus: "paid",
      })
      if (findResult) {
        if (findResult.EventTier != '线上入门票 Online Admission Ticket') {
          return findResult.EventOrderId
        } else {
          return false;  
        }        
      } else {
        return false;
      }
    },
    seminarConfirmationCode() {
      const findResult = find(this.allLinks, {
        Product: "Seminar2025",
        PaymentStatus: "paid",
      })
      if (findResult) {
        if (findResult.EventTier != '线上入门票 Online Admission Ticket') {
          return findResult.EventConfirmationCode
        } else {
          return false;  
        }        
      } else {
        return false;
      }
    },
    hasQimen2022() {
      let replays = filter(this.allLinks, item => {
        return startsWith(item.Product, "Qimen2022") && item.PaymentStatus == 'paid'
      });
      return sortBy(replays, e => parseInt(e.Product.split('#')[1]));
    },
    hasQimen2023() {
      let replays = filter(this.allLinks, item => {
        return startsWith(item.Product, "Qimen2023") && item.PaymentStatus == 'paid'
      });
      return sortBy(replays, e => parseInt(e.Product.split('#')[1]));
    },
    hasQimen2024() {
      let replays = filter(this.allLinks, item => {
        return startsWith(item.Product, "Qimen2024") && item.PaymentStatus == 'paid'
      });
      return sortBy(replays, e => parseInt(e.Product.split('#')[1]));
    },
    qimenReplays() {
      let replays = filter(this.allLinks, item => {
        return startsWith(item.Product, "Qimen2022Replay#") && item.PaymentStatus == 'paid'
      });
      return sortBy(replays, e => parseInt(e.Product.split('#')[1]));
    },
    qimen2023TurnaroundReplay() {
      return filter(this.allLinks, item => {
        return startsWith(item.Product, "QimenTurnaround2023Replay") && item.PaymentStatus == 'paid'
      });
    },
    qimen2023Replays() {
      let replays = filter(this.allLinks, item => {
        return startsWith(item.Product, "Qimen2023Replay#") && item.PaymentStatus == 'paid'
      });
      return sortBy(replays, e => parseInt(e.Product.split('#')[1]));
    },
  },
  mounted() {
    this.getAllLinks();
    this.$store.commit('clearCalendar');
  },
  methods: {
    clearData() {
      this.hour = null;
      this.minute = null;
      this.selectedTimeOfDay = null;
      this.day = null;
      this.month = null;
      this.year = null;
      this.gender = null;
      this.noTime = false;
      this.name = null;
      this.turnOnValidators = false;
      this.processingModal = false;
      this.selectedDiscountName = 'spacer1';
    },
    async buyChart() {
      this.turnOnValidators = true;

      if (!this.validDate) {
        alert("Please select a valid date");
        return;
      }
      if (
        !this.year ||
        !this.month ||
        !this.day ||
        !this.gender ||
        !this.name
      ) {
        return;
      }
      if (!this.noTime) {
        if (!this.hour || !this.minute || !this.selectedTimeOfDay) {
          return;
        }
      }

      let hour = this.selectedTimeOfDay == "AM" ? this.hour : this.hour + 12;
      hour = hour == 12 ? "0" : hour;
      hour = hour == 24 ? "12" : hour;
      hour = String(hour).padStart(2, "0");

      const birthday = `${this.year}-${String(this.month).padStart(
        2,
        "0"
      )}-${String(this.day).padStart(2, "0")}T${this.noTime ? "99" : hour}:${
        this.noTime ? "99" : this.minute
      }`;

      this.processingModal = true;
      const apiName = "payment";
      const path = "/checkout";
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        queryStringParameters: {
          birthday,
          gender: this.gender,
          name: this.name,
        },
      };

      try {
        const response = await API.get(apiName, path, myInit);
        window.location.href = response;
      } catch (e) {
        console.log(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
      }
    },
    async buyCalendar() {
      this.turnOnValidators = true;
      let myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      };

      if (this.selectedDiscountName.OrderId) {
        myInit.queryStringParameters = {
          EventOrderId: this.selectedDiscountName.OrderId
        }
      } else {
        if (!this.validDate) {
          alert("Please select a valid date");
          return;
        }
        if (
          !this.year ||
          !this.month ||
          !this.day ||
          !this.gender ||
          !this.name
        ) {
          return;
        }
        if (!this.noTime) {
          if (!this.hour || !this.minute || !this.selectedTimeOfDay) {
            return;
          }
        }

        let hour = this.selectedTimeOfDay == "AM" ? this.hour : this.hour + 12;
        hour = hour == 12 ? "0" : hour;
        hour = hour == 24 ? "12" : hour;
        hour = String(hour).padStart(2, "0");

        const birthday = `${this.year}-${String(this.month).padStart(
          2,
          "0"
        )}-${String(this.day).padStart(2, "0")}T${this.noTime ? "99" : hour}:${
          this.noTime ? "99" : this.minute
        }`;
        myInit.queryStringParameters = {
          birthday,
          gender: this.gender,
          name: this.name,
        }
      }

      this.processingModal = true;
      const apiName = "payment";
      const path = "/checkout/calendar";

      try {
        const response = await API.get(apiName, path, myInit);
        window.location.href = response;
      } catch (e) {
        console.log(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
      }
    },
    async getAllLinks() {
      this.gettingLinks = true;
      const apiName = "links";
      const path = "/";
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        queryStringParameters: {},
      };
      try {
        this.allLinks = await API.get(apiName, path, myInit);

        let charts = filter(this.allLinks, {
          Product: "EnergyChart2023",
          PaymentStatus: "paid",
        });
        this.energyCharts = await Promise.all(map(charts, chart => {
          return this.getSignedLink(chart.S3ObjectKey, 'bazi').then(url => {
            chart.url = url;
            return chart;
          })
        }))
        this.gettingLinks = false;
      } catch (e) {
        console.log(e);
        alert(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
      }
    },
    bdString(input) {
      if (input.split("T")[1] == '99:99') {
        return DateTime.fromISO(input.split("T")[0]).toLocaleString(DateTime.DATE_MED);
      } else {
        return DateTime.fromISO(input).toLocaleString(DateTime.DATETIME_MED);
      }
      
    },
    async getSignedLink(key, itemType) {
      const apiName = "links";
      const path = "/private";
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        queryStringParameters: { key, itemType },
      };
      try {
        return await API.get(apiName, path, myInit);
      } catch (e) {
        console.log(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
      }
    },
    viewFile(key) {
      this.$router.push(`/view/${key}`);
    },
    async downloadFile(obj, itemType) {
      if (obj == 'handouts.pdf') {
        this.handoutsDownloadInProgress = true;
        const url = await this.getSignedLink(obj, itemType);
        fetch(url)
          .then((res) => res.blob())
          .then((blob) =>
            saveAs(blob, `JHMetaphysics_2025SeminarHandouts`)
          )
          .then(() => (this.handoutsDownloadInProgress = false));
      } else {
        obj.downloadInProgress = true;
        const url = await this.getSignedLink(obj.S3ObjectKey, itemType);
        fetch(url)
          .then((res) => res.blob())
          .then((blob) =>
            saveAs(blob, `JHMetaphysics_${obj.S3ObjectKey.split("/")[1]}`)
          )
          .then(() => (obj.downloadInProgress = false));
      }      
    },
    calendarMonthDisplayLastYear(i) {
      return [
        'Jan-2024',
        'Feb-2024',
        'Mar-2024',
        'Apr-2024',
        'May-2024',
        'Jun-2024',
        'Jul-2024',
        'Aug-2024',
        'Sep-2024',
        'Oct-2024',
        'Nov-2024',
        'Dec-2024'
      ][parseInt(i)-1]
    },
    calendarMonthDisplay(i) {
      return [
        'Jan-2025',
        'Feb-2025',
        'Mar-2025',
        'Apr-2025',
        'May-2025',
        'Jun-2025',
        'Jul-2025',
        'Aug-2025',
        'Sep-2025',
        'Oct-2025',
        'Nov-2025',
        'Dec-2025'
      ][parseInt(i)-1]
    },
    viewCalendar(key, person) {
      this.$store.commit('keepCalendar', person)
      this.$router.push(`/calendar/${key}`);
    },
    async viewEnergy(key) {
      const url = await this.getSignedLink(key, 'bazi');
      window.open(url, '_blank');
    },
    async viewHandouts() {
      const url = await this.getSignedLink('handouts.pdf', 'bazi');
      window.open(url, '_blank');
    }
  },
};
</script>

<style scoped>
@media (max-width: 991.98px) {
  .custom-mobile-text {
    font-size: 0.9rem;
  }
  .page-header {
    margin-top: -8px;
  }
}

.services-container {
  color: #939597;
}
.services-container h5.card-title {
  color: #75787b;
}
.services-container h5.card-title-blue {
  color: #003a70;
}
.services-container h5.date {
  color: #939597;
  font-size: 0.85rem;
}
.services-container .card-text {
  font-size: 0.8rem;
}
.services-container h5 {
  font-size: 1.1rem;
}
</style>
