<template>
  <div class="container py-3" v-if="!qimen">
    <div class="d-flex align-items-center brown-text">
      <span class="en-r brown-text">计算中...</span>
      <div
        class="spinner-border ms-auto"
        role="status"
        aria-hidden="true"
      ></div>
    </div>
  </div>
  <div class="container pt-3" v-if="qimen">
    <div class="d-flex pb-3">
      <button type="button" class="btn btn-warning" @click="goPrevHour">
        <i class="fas fa-backward"></i> Previous Hour
      </button>
      <a type="button" class="btn btn-warning ms-auto" href="#/qimen/predict"
        ><i class="fas fa-binoculars"></i> 奇门预测</a
      >
      <button type="button" class="btn btn-warning ms-auto" @click="goNextHour">
        Next Hour <i class="fas fa-forward"></i>
      </button>
    </div>
    <div class="row justify-content-center align-items-center">
      <div class="col-6 col-md-4 col-lg-2 text-center">
        <div class="row">
          <div class="col en-r brown-text" style="font-size: 0.8rem">
            <img src="../assets/hourly.png" class="img-fluid" /><br />{{
              timeString
            }}
          </div>
        </div>
        <div class="row light-yellow g-0">
          <div class="col border"><span class="cn pillars">年</span></div>
          <div class="col border"><span class="cn pillars">月</span></div>
          <div class="col border"><span class="cn pillars">日</span></div>
          <div class="col border"><span class="cn pillars">时</span></div>
        </div>
        <div class="row g-0">
          <div class="col border border-bottom-0">
            <span class="cn pillars">{{ qimen.bazi.year[0] }}</span>
          </div>
          <div class="col border border-bottom-0">
            <span class="cn pillars">{{ qimen.bazi.month[0] }}</span>
          </div>
          <div class="col border border-bottom-0">
            <span class="cn pillars">{{ qimen.bazi.day[0] }}</span>
          </div>
          <div class="col border border-bottom-0">
            <span class="cn pillars">{{ qimen.bazi.hour[0] }}</span>
          </div>
        </div>
        <div class="row g-0">
          <div class="col border border-top-0">
            <span class="cn pillars">{{ qimen.bazi.year[1] }}</span>
          </div>
          <div class="col border border-top-0">
            <span class="cn pillars">{{ qimen.bazi.month[1] }}</span>
          </div>
          <div class="col border border-top-0">
            <span class="cn pillars">{{ qimen.bazi.day[1] }}</span>
          </div>
          <div class="col border border-top-0">
            <span class="cn pillars">{{ qimen.bazi.hour[1] }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
        <form class="border px-3 py-3 mb-3" novalidate>
          <div class="row mb-3">
            <label class="col-sm-2 col-form-label pt-0">日期</label>
            <div class="col-sm-3">
              <select
                class="form-select"
                v-model="day"
                :class="[
                  !day && turnOnValidators ? 'is-invalid' : '',
                  validDate ? '' : 'is-invalid',
                ]"
              >
                <option selected value="null">日</option>
                <option v-for="day in days" :value="day.label" :key="day.label">
                  {{ day.label }}
                </option>
              </select>
            </div>
            <div class="col-sm-3">
              <select
                class="form-select"
                v-model="month"
                :class="[
                  !month && turnOnValidators ? 'is-invalid' : '',
                  validDate ? '' : 'is-invalid',
                ]"
              >
                <option selected value="null">月</option>
                <option
                  v-for="month in months"
                  :value="month.value"
                  :key="month.value"
                >
                  {{ month.label }}
                </option>
              </select>
            </div>
            <div class="col-sm-4">
              <select
                class="form-select"
                v-model="year"
                :class="[
                  !year && turnOnValidators ? 'is-invalid' : '',
                  validDate ? '' : 'is-invalid',
                ]"
              >
                <option selected value="null">年</option>
                <option
                  v-for="year in years"
                  :value="year.label"
                  :key="year.label"
                >
                  {{ year.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-sm-2 col-form-label pt-0">时间</label>
            <div class="col-sm-3">
              <select
                class="form-select"
                v-model="hour"
                :class="[
                  !hour && turnOnValidators ? 'is-invalid' : '',
                ]"
              >
                <option selected value="null">时</option>
                <option
                  v-for="hour in hours"
                  :value="hour.label"
                  :key="hour.label"
                >
                  {{ hour.label }}
                </option>
              </select>
            </div>
            <div class="col-sm-3">
              <select
                class="form-select"
                v-model="minute"
                :class="[
                  !minute && turnOnValidators ? 'is-invalid' : '',
                ]"
              >
                <option selected value="null">分</option>
                <option
                  v-for="minute in minutes"
                  :value="minute.label"
                  :key="minute.value"
                >
                  {{ minute.label }}
                </option>
              </select>
            </div>
            <div class="col-sm-4">
              <select
                class="form-select"
                v-model="selectedTimeOfDay"
                :class="[
                  !selectedTimeOfDay && turnOnValidators 
                    ? 'is-invalid'
                    : '',
                ]"
              >
                <option selected value="null">AM/PM</option>
                <option v-for="t in timeOfDay" :value="t.label" :key="t.label">
                  {{ t.label }}
                </option>
              </select>
            </div>
          </div>
        </form>
        <button type="button" class="btn btn-primary mb-3" @click="processHourlyInput">
          起盘</button
        >&nbsp;
        <button type="button" class="btn btn-warning mb-3" @click="getHourly('now')">
          <i class="fas fa-clock"></i> 现在
        </button>
      </div>
    </div>
    <hr />
    <div class="row justify-content-center pt-1 pb-4">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
        <div class="row pb-2">
          <div
            class="col en-r brown-text text-center"
            style="font-size: 0.8rem"
          >
            {{ qimen.timingText }}
          </div>
        </div>
        <div class="row align-items-center">
          <div
            class="
              col-1
              text-center
              border-top border-start
              gx-0
              en-r
              木
              brown-text
            "
          >
            <div class="my-1">SE</div>
          </div>
          <!-- 巳 -->
          <div class="col border-top 木 gx-1 gx-md-2">
            <div class="d-flex flex-row justify-content-start">
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[0].includes('巳空')"
                title="巳空" data-bs-toggle="tooltip"
              >
                空
              </div>
              <div class="my-1">&nbsp;</div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[0].includes('巳马')"
                title="巳马" data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
          <!-- 午 -->
          <div class="col border-top en-r 火 gx-1 gx-md-2">
            <div
              class="d-flex flex-row justify-content-center align-items-center"
            >
              <div
                class="cn kong text-center my-1 me-2"
                v-if="qimen.奇门时盘.空马[1].includes('午空')"
                title="午空" data-bs-toggle="tooltip"
              >
                空
              </div>
              <div class="my-1 brown-text">S</div>
              <div
                class="cn kong text-center my-1 ms-2"
                v-if="qimen.奇门时盘.空马[1].includes('午马')"
                title="午马" data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
          <!-- 未 -->
          <div class="col border-top 土 gx-1 gx-md-2">
            <div class="d-flex flex-row justify-content-end">
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[2].includes('未空')"
                title='未空' data-bs-toggle="tooltip"
              >
                空
              </div>
              <div class="my-1">&nbsp;</div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[2].includes('未马')"
                title='未马' data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
          <div
            class="
              col-1
              text-center
              border-top border-end
              gx-0
              en-r
              土
              brown-text
            "
          >
            <div class="my-1">SW</div>
          </div>
        </div>
        <div class="row">
          <!-- 辰 -->
          <div class="col-1 text-center border-start gx-0 木">
            <div class="d-flex flex-column align-items-center">
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[0].includes('辰空')"
                title='辰空' data-bs-toggle="tooltip"
              >
                空
              </div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[0].includes('辰马')"
                title='辰马' data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
          <div
            class="col border gx-1 gx-md-2"
            :class="{
              pink: qimen.奇门时盘.局[1] == 'i',
            }"
          >
            <QimenPalace
              :pos="0"
              :qimen="qimen"
              :outcomePalace="redBorderMe(0)"
              :askerPalace="blueBorderMe(0) || blueBorderMeTwo(0)"
              :calendar="calendar(0)"
              :redBorderStem="redBorderStem"
            />
          </div>
          <div
            class="col border gx-1 gx-md-2"
            :class="{
              pink: qimen.奇门时盘.局[1] == 'a',
            }"
          >
            <QimenPalace
              :pos="1"
              :qimen="qimen"
              :outcomePalace="redBorderMe(1)"
              :askerPalace="blueBorderMe(1) || blueBorderMeTwo(1)"
              :calendar="calendar(1)"
              :redBorderStem="redBorderStem"
            />
          </div>
          <div
            class="col border gx-1 gx-md-2"
            :class="{
              pink: qimen.奇门时盘.局[1] == 'a',
            }"
          >
            <QimenPalace
              :pos="2"
              :qimen="qimen"
              :outcomePalace="redBorderMe(2)"
              :askerPalace="blueBorderMe(2) || blueBorderMeTwo(2)"
              :calendar="calendar(2)"
              :redBorderStem="redBorderStem"
            />
          </div>
          <!-- 申 -->
          <div class="col-1 text-center border-end gx-0 土">
            <div class="d-flex flex-column align-items-center">
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[2].includes('申空')"
                title='申空' data-bs-toggle="tooltip"
              >
                空
              </div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[2].includes('申马')"
                title='申马' data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- 卯 -->
          <div class="col-1 text-center border-start gx-0 木">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
                h-100
              "
            >
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[3].includes('卯空')"
                title='卯空' data-bs-toggle="tooltip"
              >
                空
              </div>
              <div class="en-r brown-text">E</div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[3].includes('卯马')"
                title='卯马' data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
          <div
            class="col border gx-1 gx-md-2"
            :class="{
              pink: qimen.奇门时盘.局[1] == 'i',
            }"
          >
            <QimenPalace
              :pos="3"
              :qimen="qimen"
              :outcomePalace="redBorderMe(3)"
              :askerPalace="blueBorderMe(3) || blueBorderMeTwo(3)"
              :calendar="calendar(3)"
              :redBorderStem="redBorderStem"
            />
          </div>
          <div class="col border gx-1 gx-md-2">
            <div class="row pb-2">
              <div class="col cn pe-0">&nbsp;</div>
              <div class="col flex-grow-1 text-end cn">
                {{ qimen["奇门时盘"]["地盘"][4] }}
              </div>
            </div>
            <div class="row">
              <div class="col text-center cn" style="font-size: 1.6rem">
                &nbsp;
              </div>
            </div>
            <div class="row pt-2">
              <div class="col cn">&nbsp;</div>
              <div class="col text-end cn">
                {{ qimen["奇门时盘"]["地盘"][4] }}
              </div>
            </div>
          </div>
          <div
            class="col border gx-1 gx-md-2"
            :class="{
              pink: qimen.奇门时盘.局[1] == 'a',
            }"
          >
            <QimenPalace
              :pos="5"
              :qimen="qimen"
              :outcomePalace="redBorderMe(5)"
              :askerPalace="blueBorderMe(5) || blueBorderMeTwo(5)"
              :calendar="calendar(5)"
              :redBorderStem="redBorderStem"
            />
          </div>
          <!-- 酉 -->
          <div class="col-1 text-center border-end gx-0 金">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
                h-100
              "
            >
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[5].includes('酉空')"
                title='酉空' data-bs-toggle="tooltip"
              >
                空
              </div>
              <div class="en-r brown-text">W</div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[5].includes('酉马')"
                title='酉马' data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- 寅 -->
          <div class="col-1 text-center border-start gx-0 土">
            <div
              class="
                d-flex
                flex-column-reverse
                justify-content-start
                align-items-center
                h-100
              "
            >
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[6].includes('寅空')"
                title='寅空' data-bs-toggle="tooltip"
              >
                空
              </div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[6].includes('寅马')"
                title='寅马' data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
          <div
            class="col border gx-1 gx-md-2"
            :class="{
              pink: qimen.奇门时盘.局[1] == 'i',
            }"
          >
            <QimenPalace
              :pos="6"
              :qimen="qimen"
              :outcomePalace="redBorderMe(6)"
              :askerPalace="blueBorderMe(6) || blueBorderMeTwo(6)"
              :calendar="calendar(6)"
              :redBorderStem="redBorderStem"
            />
          </div>
          <div
            class="col border gx-1 gx-md-2"
            :class="{
              pink: qimen.奇门时盘.局[1] == 'i',
            }"
          >
            <QimenPalace
              :pos="7"
              :qimen="qimen"
              :outcomePalace="redBorderMe(7)"
              :askerPalace="blueBorderMe(7) || blueBorderMeTwo(7)"
              :calendar="calendar(7)"
              :redBorderStem="redBorderStem"
            />
          </div>
          <div
            class="col border gx-1 gx-md-2"
            :class="{
              pink: qimen.奇门时盘.局[1] == 'a',
            }"
          >
            <QimenPalace
              :pos="8"
              :qimen="qimen"
              :outcomePalace="redBorderMe(8)"
              :askerPalace="blueBorderMe(8) || blueBorderMeTwo(8)"
              :calendar="calendar(8)"
              :redBorderStem="redBorderStem"
            />
          </div>
          <!-- 戌 -->
          <div class="col-1 text-center border-end gx-0 金">
            <div
              class="
                d-flex
                flex-column-reverse
                justify-content-start
                align-items-center
                h-100
              "
            >
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[8].includes('戌空')"
                title='戌空' data-bs-toggle="tooltip"
              >
                空
              </div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[8].includes('戌马')"
                title='戌马' data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="
              col-1
              text-center
              border-start border-bottom
              gx-0
              en-r
              土
              brown-text
            "
          >
            <div class="my-1">NE</div>
          </div>
          <!-- 丑 -->
          <div class="col border-bottom 土 gx-1 gx-md-2">
            <div class="d-flex flex-row justify-content-start">
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[6].includes('丑空')"
                title='丑空' data-bs-toggle="tooltip"
              >
                空
              </div>
              <div class="my-1">&nbsp;</div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[6].includes('丑马')"
                title='丑马' data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
          <!-- 子 -->
          <div class="col text-center border-bottom en-r 水 gx-1 gx-md-2">
            <div
              class="d-flex flex-row justify-content-center align-items-center"
            >
              <div
                class="cn kong text-center my-1 me-2"
                v-if="qimen.奇门时盘.空马[7].includes('子空')"
                title='子空' data-bs-toggle="tooltip"
              >
                空
              </div>
              <div class="my-1 brown-text">N</div>
              <div
                class="cn kong text-center my-1 ms-2"
                v-if="qimen.奇门时盘.空马[7].includes('子马')"
                title='子马' data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
          <!-- 亥 -->
          <div class="col border-bottom text-end 金 gx-1 gx-md-2">
            <div class="d-flex flex-row justify-content-end">
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[8].includes('亥马')"
                title='亥马' data-bs-toggle="tooltip"
              >
                马
              </div>
              <div class="my-1">&nbsp;</div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[8].includes('亥空')"
                title='亥空' data-bs-toggle="tooltip"
              >
                空
              </div>
            </div>
          </div>
          <div
            class="
              col-1
              text-center
              border-bottom border-end
              gx-0
              en-r
              金
              brown-text
            "
          >
            <div class="my-1">NW</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import QimenPalace from "@/components/QimenPalace.vue";
import { Auth, API } from "aws-amplify";
import { DateTime } from "luxon";
import { Tooltip } from "bootstrap";

const inclusiveRange = (start, end, step) => {
  return Array.from(
    Array.from(Array(Math.ceil((end - start + 1) / step)).keys()),
    (x) => {
      return { label: start + x * step };
    }
  );
};

export default {
  name: "QimenHourly",
  components: {
    QimenPalace,
  },
  data: () => ({
    qimen: null,
    outcomeToggle: "",
    redBorderStem: "",
    blueBorderStem: "",
    blueBorderStemTwo: "",
    days: Array.from(Array(31).keys(), (n) => {
      return { label: n + 1 };
    }),
    months: [
      { label: "Jan (1)", value: 1 },
      { label: "Feb (2)", value: 2 },
      { label: "Mar (3)", value: 3 },
      { label: "Apr (4)", value: 4 },
      { label: "May (5)", value: 5 },
      { label: "Jun (6)", value: 6 },
      { label: "Jul (7)", value: 7 },
      { label: "Aug (8)", value: 8 },
      { label: "Sep (9)", value: 9 },
      { label: "Oct (10)", value: 10 },
      { label: "Nov (11)", value: 11 },
      { label: "Dec (12)", value: 12 },
    ],
    years: inclusiveRange(2025, 1918, -1),
    hours: Array.from(Array(12).keys(), (n) => {
      return { label: n + 1 };
    }),
    minutes: Array.from(Array(60).keys(), (n) => {
      return { label: String(n).padStart(2, "0") };
    }),
    timeOfDay: Array.from(["AM", "PM"], (n) => {
      return { label: n };
    }),
    day: null,
    month: null,
    year: null,
    hour: null,
    minute: null,
    selectedTimeOfDay: null,
    timestamp: null,
    turnOnValidators: true,
  }),
  watch: {},
  computed: {
    timeString() {
      return this.qimen
        ? DateTime.fromISO(
            this.qimen.queryStringParameters.time.includes(".")
            ? this.qimen.queryStringParameters.time.split(".")[0]
            : this.qimen.queryStringParameters.time
          ).toLocaleString(DateTime.DATETIME_MED)
        : "";
    },
    validDate() {
      if (this.year && this.month && this.day) {
        return DateTime.fromObject({
          year: this.year,
          month: this.month,
          day: this.day,
        }).isValid;
      }
      return true;
    },
  },
  mounted() {
    this.getHourly("now");
    let tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  },
  methods: {
    async getHourly(time) {
      //this.qimen = JSON.parse('{"time":"",{"局":"Yin4","地盘":["戊","壬","庚","己","乙","丁","癸","辛","丙"],"天盘":["癸","己","戊","辛","","壬","丙","丁","庚"],"八门":["1休","8生","3伤","6开",null,"4杜","7惊","2死","9景"],"九星":["天任","天冲","天辅","天蓬",null,"天英","天心","天柱","天芮"],"八神":["六合","太阴","腾蛇","白虎",null,"值符","玄武","九地","九天"],"空亡":[[],[],[],["卯空"],[],[],["寅空"],[],[]],"马星":["巳马","","","","","","","",""],"空马":["巳马","","","卯空","","","寅空","",""],"天盘left":["","","","","","","","","乙"]}')
      
      //console.log(time)
      const apiName = "qimenpredict";
      const path = "/hourly";
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        // 盘伏吟/反吟??
        //queryStringParameters: { time: "2022-04-25T13:49" },
        //queryStringParameters: { time: "2025-05-22T09:49" },
        //queryStringParameters: { time: "2022-05-03T06:07" },
        queryStringParameters: {
          time:
            time == "now"
              ? DateTime.now().toISO().split(".")[0]
              : time.includes(".")
              ? time.split(".")[0]
              : time,
        },
      };
      try {
        this.qimen = await API.get(apiName, path, myInit);
        let timestamp = DateTime.fromISO(this.qimen.queryStringParameters.time)
        this.day = timestamp.day
        this.month = timestamp.month
        this.year = timestamp.year
        this.hour = parseInt(timestamp.toFormat('h'))
        this.minute = timestamp.toFormat('mm')
        this.selectedTimeOfDay = timestamp.toFormat('a')
        
        /* month chart generator - manual
        this.qimen.奇门时盘 = JSON.parse('{"局":"Yin4","地盘":["戊","壬","庚","己","乙","丁","癸","辛","丙"],"天盘":["戊","壬","庚","己","","丁","癸","辛","丙"],"八门":["6开","1休","8生","7惊",null,"3伤","2死","9景","4杜"],"九星":["天辅","天英","天芮","天冲",null,"天柱","天任","天蓬","天心"],"八神":["腾蛇","值符","九天","太阴",null,"九地","六合","白虎","玄武"],"空亡":[[],[],[],["卯空"],[],[],["寅空"],[],[]],"马星":["","","","","","","寅马","",""],"空马":["","","","卯空","","","寅空寅马","",""],"天盘left":["","","乙","","","","","",""]}')
        this.qimen.奇门时盘.门迫 = [false,false,false,false,false,false,false,false,false]*/
      } catch (e) {
        console.log(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
        this.$router.push("/");
      }
    },
    async processHourlyInput() {
      if (!this.validDate) {
        alert("请输入有效日期与时间");
        return;
      }
      if (!this.year || !this.month || !this.day) {
        return;
      }
      if (!this.hour || !this.minute || !this.selectedTimeOfDay) {
        return;
      }
      let hour = this.selectedTimeOfDay == "AM" ? this.hour : this.hour + 12;
      hour = hour == 12 ? "0" : hour;
      hour = hour == 24 ? "12" : hour;
      hour = String(hour).padStart(2, "0");

      const birthday = `${this.year}-${String(this.month).padStart(
        2,
        "0"
      )}-${String(this.day).padStart(2, "0")}T${hour}:${this.minute}`;
      await this.getHourly(birthday);
    },
    getPalacePos(stem) {
      if (this.qimen) {
        const stemLocation = this.qimen.奇门时盘.天盘.indexOf(stem);
        return stemLocation == -1
          ? this.qimen.奇门时盘.天盘left.indexOf(stem)
          : stemLocation;
      }
      return -1;
    },
    redBorderMe(pos) {
      if (this.outcomeToggle == "hourOutcome") {
        if (pos == this.hourOutcome) {
          return true;
        }
      } else if (pos == this.getPalacePos(this.redBorderStem)) {
        return true;
      }
      return false;
    },
    blueBorderMe(pos) {
      if (pos == this.getPalacePos(this.blueBorderStem)) {
        return true;
      }
      return false;
    },
    blueBorderMeTwo(pos) {
      if (pos == this.getPalacePos(this.blueBorderStemTwo)) {
        return true;
      }
      return false;
    },
    calendar(pos) {
      return ["4-5", "6", "7-8", "3", "", "9", "1-2", "12", "10-11"][pos];
    },
    async goPrevHour() {
      const timeString = this.qimen.queryStringParameters.time.includes(".")
        ? this.qimen.queryStringParameters.time.split(".")[0]
        : this.qimen.queryStringParameters.time;
      this.qimen = null;
      await this.getHourly(
        DateTime.fromISO(timeString).minus({ hours: 2 }).toISO()
      );
    },
    async goNextHour() {
      const timeString = this.qimen.queryStringParameters.time.includes(".")
        ? this.qimen.queryStringParameters.time.split(".")[0]
        : this.qimen.queryStringParameters.time;
      this.qimen = null;
      await this.getHourly(
        DateTime.fromISO(timeString).plus({ hours: 2 }).toISO()
      );
    },
  },
};
</script>

<style scoped>
.cn {
  font-family: adobe-kaiti-std, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #613914;
}
.en-l {
  font-family: alibaba-puhuiti, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.en-r {
  font-family: alibaba-puhuiti, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.kong {
  width: 1.25em;
  background-color: #ffee58;
}

.金 {
  background-color: #d9cb9e;
}
.木 {
  background-color: #1bbc9b;
}
.水 {
  background-color: #4cb2f7;
}
.火 {
  background-color: #ff8080;
}
.土 {
  background-color: #ebaf70;
}

.pink {
  background-color: #f9e7e6;
}
.light-yellow {
  background-color: #fefaea;
}
.brown-text {
  color: #613914;
}

.pillars {
  font-size: 1rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #613914;
}
.nav-link {
  color: #613914;
}
.nav-link:hover {
  color: #b46723;
}

.gauge {
  width: 100%;
  max-width: 100px;
  font-size: 1rem;
  color: #613914;
  font-family: alibaba-puhuiti, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.gauge__body {
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: #b4c0be;
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
}

.gauge__fill {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;
  /*background: rgba(255, 0, 0, 1);*/
  transform-origin: center top;
  transform: rotate(0.25turn);
  transition: transform 0.2s ease-out;
}

.gauge__cover {
  width: 75%;
  height: 150%;
  background: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);

  /* Text */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25%;
  box-sizing: border-box;
}
.gauge-label {
  font-size: 0.65em;
}

.btn-brown {
  color: #fff;
  background-color: #613914;
}
.btn-brown:hover {
  color: #fff;
  background-color: #b46723;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
