<template>
  <div class="container py-3" v-if="!qimen">
    <div class="d-flex align-items-center brown-text">
              <span class="en-r brown-text">计算中...</span>
              <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
  </div>
  <div class="container pt-3" v-if="qimen">
    <div class="d-flex pb-3" v-if="qimen ? qimen.tier == 'admin' : false">
      <button type="button" class="btn btn-warning" @click="goPrevHour"><i class="fas fa-backward"></i> Previous Hour</button>
      <a type="button" class="btn btn-warning ms-auto" href="#/qimen/hourly"><i class="far fa-clock"></i> 奇门时盘</a>      
      <button type="button" class="btn btn-secondary ms-auto" data-bs-toggle="modal" data-bs-target="#exampleModal"><i class="far fa-clock"></i> 预测选时</button>
      <a type="button" class="btn btn-secondary ms-auto" href="#/qimen/day"><i class="far fa-clock"></i> 日盘</a>
      <button type="button" class="btn btn-warning ms-auto" @click="goNextHour">Next Hour <i class="fas fa-forward"></i></button>
    </div>
    <div class="d-flex pb-3" v-if="qimen ? qimen.tier == 'basic' || qimen.tier == 'advanced' || qimen.tier == 'admin' : false">
      <!-- <a type="button" class="btn btn-warning mx-auto" href="#/"><i class="fas fa-video"></i> 奇门预测班视频</a> -->
      <a type="button" class="btn btn-warning mx-auto" href="#/qimen/strategy"><i class="fas fa-chess"></i> 奇门运筹</a>
      <a type="button" class="btn btn-warning mx-auto" href="#/qimen/wealth"><i class="fas fa-briefcase"></i> 奇门财商</a>
    </div>
    <div class="row justify-content-center align-items-center">
      <div class="col-6 col-md-4 col-lg-2 text-center">
        <div class="row">
          <div class="col en-r brown-text" style="font-size: 0.8rem">
            <img
        src="../assets/predict.png"
        class="img-fluid"
      /><br />{{ timeString }}&nbsp;&nbsp;<a @click="refreshTime"><i class="fas fa-redo"></i></a>
          </div>
        </div>
        <div class="row light-yellow g-0">
          <div class="col border"><span class="cn pillars">年</span></div>
          <div class="col border"><span class="cn pillars">月</span></div>
          <div class="col border"><span class="cn pillars">日</span></div>
          <div class="col border"><span class="cn pillars">时</span></div>
        </div>
        <div class="row g-0">
          <div class="col border border-bottom-0">
            <span class="cn pillars">{{ qimen.bazi.year[0] }}</span>
          </div>
          <div class="col border border-bottom-0">
            <span class="cn pillars">{{ qimen.bazi.month[0] }}</span>
          </div>
          <div class="col border border-bottom-0">
            <span class="cn pillars">{{ qimen.bazi.day[0] }}</span>
          </div>
          <div
            class="col border"
            :class="{
              'border-bottom-0': qimen.predict.outcome != qimen.bazi.hour[0] || outcomeToggle != 'hourOutcome',
              'border-danger': outcomeToggle == 'hourOutcome',
            }"
          >
            <span class="cn pillars">{{ qimen.bazi.hour[0] }}</span>
          </div>
        </div>
        <div class="row g-0">
          <div class="col border border-top-0">
            <span class="cn pillars">{{ qimen.bazi.year[1] }}</span>
          </div>
          <div class="col border border-top-0">
            <span class="cn pillars">{{ qimen.bazi.month[1] }}</span>
          </div>
          <div class="col border border-top-0">
            <span class="cn pillars">{{ qimen.bazi.day[1] }}</span>
          </div>
          <div
            class="col border border-top-0"
            :class="{
              'border-danger':
                qimen.predict.outcome != qimen.bazi.hour[0] &&
                outcomeToggle == 'hourOutcome',
            }"
          >
            <span class="cn pillars">{{ qimen.bazi.hour[1] }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
        <ul
          class="nav nav-pills mb-3 justify-content-center pt-1"
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active px-3 px-sm-2 px-lg-1 py-1 en-r"
              id="pills-基本-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-基本"
              type="button"
              role="tab"
              @click="this.predictionMode = '基本'"
            >
              基本
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link px-3 px-sm-2 px-lg-1 py-1 en-r"
              id="pills-年干-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-年干"
              type="button"
              role="tab"
              @click="this.predictionMode = '年干'"
            >
              年干
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link px-3 px-sm-2 px-lg-1 py-1 en-r"
              id="pills-比较-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-比较"
              type="button"
              role="tab"
              @click="this.predictionMode = '比较'"
            >
              比较
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link px-3 px-sm-2 px-lg-1 py-1 en-r"
              id="pills-盲选-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-盲选"
              type="button"
              role="tab"
              @click="this.predictionMode = '盲选'"
            >
              盲选
            </button>
          </li>
          <div class="w-100 d-block d-sm-none"></div>
          <li class="nav-item" role="presentation" v-if="qimen ? qimen.tier == 'advanced' || qimen.tier == 'admin' : false">
            <button
              class="nav-link px-3 px-sm-2 px-lg-1 py-1 en-r"
              id="pills-失物-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-失物"
              type="button"
              role="tab"
              @click="this.predictionMode = '基本'"
            >
              失物
            </button>
          </li>
          <li class="nav-item" role="presentation" v-if="qimen ? qimen.tier == 'advanced' || qimen.tier == 'admin' : false">
            <button
              class="nav-link px-3 px-sm-2 px-lg-1 py-1 en-r"
              id="pills-事业-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-事业"
              type="button"
              role="tab"
              @click="this.predictionMode = '事业'"
            >
              事业
            </button>
          </li>
          <!-- force 2nd line on smaller devices -->
          <div class="w-100 d-none d-sm-block d-md-none"></div>
          <li class="nav-item" role="presentation" v-if="qimen ? qimen.tier == 'advanced' || qimen.tier == 'admin' : false">
            <button
              class="nav-link px-3 px-sm-2 px-lg-1 py-1 en-r"
              id="pills-生意-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-生意"
              type="button"
              role="tab"
              @click="this.predictionMode = '生意'"
            >
              生意
            </button>
          </li>
          <li class="nav-item" role="presentation" v-if="qimen ? qimen.tier == 'advanced' || qimen.tier == 'admin' : false">
            <button
              class="nav-link px-3 px-sm-2 px-lg-1 py-1 en-r"
              id="pills-疾病-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-疾病"
              type="button"
              role="tab"
              @click="this.predictionMode = '疾病'"
            >
              疾病
            </button>
          </li>
          <div class="w-100 d-block d-sm-none"></div>
          <li class="nav-item" role="presentation" v-if="qimen ? qimen.tier == 'advanced' || qimen.tier == 'admin' : false">
            <button
              class="nav-link px-3 px-sm-2 px-lg-1 py-1 en-r"
              id="pills-婚姻-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-婚姻"
              type="button"
              role="tab"
              @click="this.predictionMode = '婚姻'"
            >
              婚姻
            </button>
          </li>
          <li class="nav-item" role="presentation" v-if="qimen ? qimen.tier == 'advanced' || qimen.tier == 'admin' : false">
            <button
              class="nav-link px-3 px-sm-2 px-lg-1 py-1 en-r"
              id="pills-学业-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-学业"
              type="button"
              role="tab"
              @click="this.predictionMode = '学业'"
            >
              学业
            </button>
          </li>
          <li class="nav-item" role="presentation" v-if="qimen ? qimen.tier == 'advanced' || qimen.tier == 'admin' : false">
            <button
              class="nav-link px-3 px-sm-2 px-lg-1 py-1 en-r"
              id="pills-危机-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-危机"
              type="button"
              role="tab"
              @click="this.predictionMode = '危机'"
            >
              危机
            </button>
          </li>
          <li class="nav-item" role="presentation" v-if="qimen ? qimen.tier == 'admin' : false">
            <button
              class="nav-link px-3 px-sm-2 px-lg-1 py-1 en-r"
              id="pills-股票-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-股票"
              type="button"
              role="tab"
              @click="this.predictionMode = '股票'"
            >
              股票
            </button>
          </li>
        </ul>
        <hr class="mt-0" />
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-基本"
            role="tabpanel"
          >
            <div class="d-flex flew-row align-items-center">
              <div class="col-4">
                <div class="gauge mx-auto">
                  <div class="gauge__body">
                    <div
                      class="gauge__fill"
                      :style="{
                        transform: `rotate(${interactionScore / 2}turn)`,
                      }"
                      :class="interactionScoreColor"
                    ></div>
                    <div class="gauge__cover">
                      {{ interactionScoreDisplay }}
                    </div>
                  </div>
                </div>
                <div class="gauge mx-auto gauge-label">
                  <span>凶</span><span class="float-end">吉&nbsp;</span>
                </div>
              </div>
              <div class="col text-center">
                <div class="row g-0">
                  <div class="col"><span class="cn pillars">&nbsp;</span></div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">九星</span>
                  </div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">宫位</span>
                  </div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">八门</span>
                  </div>
                </div>
                <div class="row g-0" v-if="asker">
                  <div class="col"><span class="cn">问者</span></div>
                  <div class="col">
                    <span class="cn">{{ asker["星_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ asker["宫_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ asker["门_full"][1] }}门</span>
                  </div>
                </div>
                <div class="row g-0" v-if="interaction">
                  <div class="col"><span class="cn">&nbsp;</span></div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['星'])"
                  >
                    <span>{{ interaction["星"] }}</span>
                  </div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['宫'])"
                  >
                    <span>{{ interaction["宫"] }}</span>
                  </div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['门'])"
                  >
                    <span>{{ interaction["门"] }}</span>
                  </div>
                </div>
                <div class="row g-0" v-if="outcome">
                  <div class="col"><span class="cn">结果</span></div>
                  <div class="col">
                    <span class="cn">{{ outcome["星_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ outcome["宫_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ outcome["门_full"][1] }}门</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-年干" role="tabpanel">
            <div class="d-flex flew-row align-items-center">
              <div class="col-4">
                <div class="mb-1">
                  <label class="form-label cn">问者年干：</label>
                  <select
                    class="form-select form-select-sm cn"
                    v-model="selected年干"
                  >
                    <option disabled value="">请选择</option>
                    <option value="庚">庚 (位数0)</option>
                    <option value="辛">辛 (位数1)</option>
                    <option value="壬">壬 (位数2)</option>
                    <option value="癸">癸 (位数3)</option>
                    <option value="甲子">甲子(鼠)=戊 (位数4)</option>
                    <option value="甲戌">甲戌(狗)=己 (位数4)</option>
                    <option value="甲申">甲申(猴)=庚 (位数4)</option>
                    <option value="甲午">甲午(马)=辛 (位数4)</option>
                    <option value="甲辰">甲辰(龙)=壬 (位数4)</option>
                    <option value="甲寅">甲寅(虎)=癸 (位数4)</option>
                    <option value="乙">乙 (位数5)</option>
                    <option value="丙">丙 (位数6)</option>
                    <option value="丁">丁 (位数7)</option>
                    <option value="戊">戊 (位数8)</option>
                    <option value="己">己 (位数9)</option>
                  </select>
                </div>
                <div class="gauge mx-auto mt-2" v-if="selected年干">
                  <div class="gauge__body">
                    <div
                      class="gauge__fill"
                      :style="{
                        transform: `rotate(${interactionScore / 2}turn)`,
                      }"
                      :class="interactionScoreColor"
                    ></div>
                    <div class="gauge__cover">
                      {{ interactionScoreDisplay }}
                    </div>
                  </div>
                </div>
                <div class="gauge mx-auto gauge-label" v-if="selected年干">
                  <span>凶</span><span class="float-end">吉&nbsp;</span>
                </div>
              </div>
              <div class="col text-center">
                <div class="row g-0" v-if="selected年干">
                  <div class="col"><span class="cn pillars">&nbsp;</span></div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">九星</span>
                  </div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">宫位</span>
                  </div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">八门</span>
                  </div>
                </div>
                <div class="row g-0" v-if="asker">
                  <div class="col"><span class="cn">问者</span></div>
                  <div class="col">
                    <span class="cn">{{ asker["星_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ asker["宫_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ asker["门_full"][1] }}门</span>
                  </div>
                </div>
                <div class="row g-0" v-if="interaction">
                  <div class="col"><span class="cn">&nbsp;</span></div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['星'])"
                  >
                    <span>{{ interaction["星"] }}</span>
                  </div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['宫'])"
                  >
                    <span>{{ interaction["宫"] }}</span>
                  </div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['门'])"
                  >
                    <span>{{ interaction["门"] }}</span>
                  </div>
                </div>
                <div class="row g-0" v-if="outcome">
                  <div class="col"><span class="cn">结果</span></div>
                  <div class="col">
                    <span class="cn">{{ outcome["星_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ outcome["宫_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ outcome["门_full"][1] }}门</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-比较" role="tabpanel">
            <div class="d-flex flew-row align-items-center">
              <div class="col-4">
                <div class="mb-1">
                  <label class="form-label cn">问者年干：</label>
                  <select
                    class="form-select form-select-sm cn"
                    v-model="selected年干"
                  >
                    <option disabled value="">请选择</option>
                    <option value="庚">庚 (位数0)</option>
                    <option value="辛">辛 (位数1)</option>
                    <option value="壬">壬 (位数2)</option>
                    <option value="癸">癸 (位数3)</option>
                    <option value="甲子">甲子(鼠)=戊 (位数4)</option>
                    <option value="甲戌">甲戌(狗)=己 (位数4)</option>
                    <option value="甲申">甲申(猴)=庚 (位数4)</option>
                    <option value="甲午">甲午(马)=辛 (位数4)</option>
                    <option value="甲辰">甲辰(龙)=壬 (位数4)</option>
                    <option value="甲寅">甲寅(虎)=癸 (位数4)</option>
                    <option value="乙">乙 (位数5)</option>
                    <option value="丙">丙 (位数6)</option>
                    <option value="丁">丁 (位数7)</option>
                    <option value="戊">戊 (位数8)</option>
                    <option value="己">己 (位数9)</option>
                  </select>
                </div>
                <div class="mb-1">
                  <label class="form-label cn">结果年干：</label>
                  <select
                    class="form-select form-select-sm cn"
                    v-model="selected年干outcome"
                  >
                    <option disabled value="">请选择</option>
                    <option value="庚">庚 (位数0)</option>
                    <option value="辛">辛 (位数1)</option>
                    <option value="壬">壬 (位数2)</option>
                    <option value="癸">癸 (位数3)</option>
                    <option value="甲子">甲子(鼠)=戊 (位数4)</option>
                    <option value="甲戌">甲戌(狗)=己 (位数4)</option>
                    <option value="甲申">甲申(猴)=庚 (位数4)</option>
                    <option value="甲午">甲午(马)=辛 (位数4)</option>
                    <option value="甲辰">甲辰(龙)=壬 (位数4)</option>
                    <option value="甲寅">甲寅(虎)=癸 (位数4)</option>
                    <option value="乙">乙 (位数5)</option>
                    <option value="丙">丙 (位数6)</option>
                    <option value="丁">丁 (位数7)</option>
                    <option value="戊">戊 (位数8)</option>
                    <option value="己">己 (位数9)</option>
                  </select>
                </div>
              </div>
              <div class="col text-center align-items-center">
                <div class="row g-0" v-if="selected年干 && selected年干outcome">
                  <div class="col-2">
                    <span class="cn pillars">&nbsp;</span>
                  </div>
                  <div class="col">
                    <div class="gauge mx-auto">
                      <div class="gauge__body">
                        <div
                          class="gauge__fill"
                          :style="{
                            transform: `rotate(${interactionScore / 2}turn)`,
                          }"
                          :class="interactionScoreColor"
                        ></div>
                        <div class="gauge__cover">
                          {{ interactionScoreDisplay }}
                        </div>
                      </div>
                    </div>
                    <div class="gauge mx-auto gauge-label mb-2">
                  <span class="float-start">凶</span><span class="float-end">吉&nbsp;</span>
                </div>
                  </div>
                </div>
                <div class="row g-0" v-if="selected年干 && selected年干outcome">
                  <div class="col"><span class="cn pillars">&nbsp;</span></div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">九星</span>
                  </div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">宫位</span>
                  </div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">八门</span>
                  </div>
                </div>
                <div class="row g-0" v-if="asker">
                  <div class="col"><span class="cn">问者</span></div>
                  <div class="col">
                    <span class="cn">{{ asker["星_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ asker["宫_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ asker["门_full"][1] }}门</span>
                  </div>
                </div>
                <div class="row g-0" v-if="interaction">
                  <div class="col"><span class="cn">&nbsp;</span></div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['星'])"
                  >
                    <span>{{ interaction["星"] }}</span>
                  </div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['宫'])"
                  >
                    <span>{{ interaction["宫"] }}</span>
                  </div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['门'])"
                  >
                    <span>{{ interaction["门"] }}</span>
                  </div>
                </div>
                <div class="row g-0" v-if="outcome">
                  <div class="col"><span class="cn">结果</span></div>
                  <div class="col">
                    <span class="cn">{{ outcome["星_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ outcome["宫_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ outcome["门_full"][1] }}门</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-失物" role="tabpanel">
            <div class="text-center brown-text en-r" v-if="qimen.predict.lostitem">{{ qimen.predict.lostitem }}</div>
          </div>
          <div class="tab-pane fade" id="pills-事业" role="tabpanel">
            <div class="d-flex flew-row align-items-center">
              <div class="col-4">
                <div class="mb-1">
                  <label class="form-label cn">问者年干：</label>
                  <select
                    class="form-select form-select-sm cn"
                    v-model="selected年干"
                  >
                    <option disabled value="">请选择</option>
                    <option value="庚">庚 (位数0)</option>
                    <option value="辛">辛 (位数1)</option>
                    <option value="壬">壬 (位数2)</option>
                    <option value="癸">癸 (位数3)</option>
                    <option value="甲子">甲子(鼠)=戊 (位数4)</option>
                    <option value="甲戌">甲戌(狗)=己 (位数4)</option>
                    <option value="甲申">甲申(猴)=庚 (位数4)</option>
                    <option value="甲午">甲午(马)=辛 (位数4)</option>
                    <option value="甲辰">甲辰(龙)=壬 (位数4)</option>
                    <option value="甲寅">甲寅(虎)=癸 (位数4)</option>
                    <option value="乙">乙 (位数5)</option>
                    <option value="丙">丙 (位数6)</option>
                    <option value="丁">丁 (位数7)</option>
                    <option value="戊">戊 (位数8)</option>
                    <option value="己">己 (位数9)</option>
                  </select>
                </div>
                <div class="gauge mx-auto mt-2" v-if="selected年干">
                  <div class="gauge__body">
                    <div
                      class="gauge__fill"
                      :style="{
                        transform: `rotate(${interactionScore / 2}turn)`,
                      }"
                      :class="interactionScoreColor"
                    ></div>
                    <div class="gauge__cover">
                      {{ interactionScoreDisplay }}
                    </div>
                  </div>
                </div>
                <div class="gauge mx-auto gauge-label" v-if="selected年干">
                  <span>凶</span><span class="float-end">吉&nbsp;</span>
                </div>
              </div>
              <div class="col text-center">
                <div class="row g-0" v-if="selected年干">
                  <div class="col"><span class="cn pillars">&nbsp;</span></div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">九星</span>
                  </div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">宫位</span>
                  </div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">八门</span>
                  </div>
                </div>
                <div class="row g-0" v-if="asker">
                  <div class="col"><span class="cn">问者</span></div>
                  <div class="col">
                    <span class="cn">{{ asker["星_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ asker["宫_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ asker["门_full"][1] }}门</span>
                  </div>
                </div>
                <div class="row g-0" v-if="interaction">
                  <div class="col"><span class="cn">&nbsp;</span></div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['星'])"
                  >
                    <span>{{ interaction["星"] }}</span>
                  </div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['宫'])"
                  >
                    <span>{{ interaction["宫"] }}</span>
                  </div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['门'])"
                  >
                    <span>{{ interaction["门"] }}</span>
                  </div>
                </div>
                <div class="row g-0" v-if="outcome">
                  <div class="col"><span class="cn">结果</span></div>
                  <div class="col">
                    <span class="cn">{{ outcome["星_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ outcome["宫_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ outcome["门_full"][1] }}门</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-疾病" role="tabpanel">
            <div class="mb-1 text-center">
              <label class="form-label cn">请选择：</label>
                  <div class="form-check form-check-inline cn">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="sickness" v-model="sicknessPredictionType">
                    <label class="form-check-label" for="inlineRadio1">疾病现况</label>
                  </div>
                  <div class="form-check form-check-inline cn">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="doctor.chinese" v-model="sicknessPredictionType">
                    <label class="form-check-label" for="inlineRadio2">求医（中医）</label>
                  </div>
                  <div class="form-check form-check-inline cn">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="doctor.western" v-model="sicknessPredictionType">
                    <label class="form-check-label" for="inlineRadio3">求医（西医）</label>
                  </div>
                </div>
              <div class="text-center brown-text">
                <span class="en-r">
                  {{ sicknessDisplayText }}
                </span>
              </div>
          </div>
          <div class="tab-pane fade" id="pills-婚姻" role="tabpanel">
            <div class="mb-1 text-center">
              <label class="form-label cn">请选择：</label>
                  <div class="form-check form-check-inline cn">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="男方" v-model="marriageSelectedGender">
                    <label class="form-check-label" for="inlineRadio1">男方</label>
                  </div>
                  <div class="form-check form-check-inline cn">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="女方" v-model="marriageSelectedGender">
                    <label class="form-check-label" for="inlineRadio2">女方</label>
                  </div>
                </div>
            <div class="d-flex flew-row align-items-center">
              <div class="col text-center align-items-center">
                <div class="row g-0" v-if="marriageSelectedGender">
                  <div class="col-2 d-none">
                    <span class="cn pillars">&nbsp;</span>
                  </div>
                  <div class="col">
                    <span class="en-r pillars brown-text">{{ marriageSelectedGender }} vs {{ marriageSelectedGender == '男方' ? '女方' : '男方' }}</span>
                  </div>
                </div>
                <div class="row g-0" v-if="marriageSelectedGender">
                  <div class="col-2 d-none">
                    <span class="cn pillars">&nbsp;</span>
                  </div>
                  <div class="col">
                    <div class="gauge mx-auto mt-2">
                      <div class="gauge__body">
                        <div
                          class="gauge__fill"
                          :style="{
                            transform: `rotate(${marriage.vs[marriageSelectedGender].interactionScore / 2}turn)`,
                          }"
                          :class="marriage.vs[marriageSelectedGender].interactionScoreColor"
                        ></div>
                        <div class="gauge__cover">
                          {{ marriage.vs[marriageSelectedGender].interactionScoreDisplay }}
                        </div>
                      </div>
                    </div>
                    <div class="gauge mx-auto gauge-label mb-2">
                  <span class="float-start">凶</span><span class="float-end">吉&nbsp;</span>
                </div>
                  </div>
                </div>
                <div class="row g-0" v-if="marriageSelectedGender">
                  <div class="col d-none"><span class="cn pillars">&nbsp;</span></div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">九星</span>
                  </div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">宫位</span>
                  </div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">八门</span>
                  </div>
                </div>
                <div class="row g-0" v-if="marriageSelectedGender">
                  <div class="col d-none"><span class="cn">男方</span></div>
                  <div class="col">
                    <span class="cn">{{ marriage.vs[marriageSelectedGender].asker["星_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ marriage.vs[marriageSelectedGender].asker["宫_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ marriage.vs[marriageSelectedGender].asker["门_full"][1] }}门</span>
                  </div>
                </div>
                <div class="row g-0" v-if="marriageSelectedGender">
                  <div class="col d-none"><span class="cn">&nbsp;</span></div>
                  <div
                    class="col"
                    :class="interactionBorder(marriage.vs[marriageSelectedGender].interaction['星'])"
                  >
                    <span>{{ marriage.vs[marriageSelectedGender].interaction["星"] }}</span>
                  </div>
                  <div
                    class="col"
                    :class="interactionBorder(marriage.vs[marriageSelectedGender].interaction['宫'])"
                  >
                    <span>{{ marriage.vs[marriageSelectedGender].interaction["宫"] }}</span>
                  </div>
                  <div
                    class="col"
                    :class="interactionBorder(marriage.vs[marriageSelectedGender].interaction['门'])"
                  >
                    <span>{{ marriage.vs[marriageSelectedGender].interaction["门"] }}</span>
                  </div>
                </div>
                <div class="row g-0" v-if="marriageSelectedGender">
                  <div class="col d-none"><span class="cn">女方</span></div>
                  <div class="col">
                    <span class="cn">{{ marriage.vs[marriageSelectedGender].outcome["星_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ marriage.vs[marriageSelectedGender].outcome["宫_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ marriage.vs[marriageSelectedGender].outcome["门_full"][1] }}门</span>
                  </div>
                </div>
              </div>
              <div class="vr mx-2" v-if="marriageSelectedGender">
              </div>
              <div class="col text-center align-items-center">
                <div class="row g-0" v-if="marriageSelectedGender">
                  <div class="col-2 d-none">
                    <span class="cn pillars">&nbsp;</span>
                  </div>
                  <div class="col">
                    <span class="en-r pillars brown-text">{{ marriageSelectedGender }} vs 六合</span>
                  </div>
                </div>
                <div class="row g-0" v-if="marriageSelectedGender">
                  <div class="col-2 d-none">
                    <span class="cn pillars">&nbsp;</span>
                  </div>
                  <div class="col">
                    <div class="gauge mx-auto mt-2">
                      <div class="gauge__body">
                        <div
                          class="gauge__fill"
                          :style="{
                            transform: `rotate(${marriage[marriageSelectedGender].interactionScore / 2}turn)`,
                          }"
                          :class="marriage[marriageSelectedGender].interactionScoreColor"
                        ></div>
                        <div class="gauge__cover">
                          {{ marriage[marriageSelectedGender].interactionScoreDisplay }}
                        </div>
                      </div>
                    </div>
                    <div class="gauge mx-auto gauge-label mb-2">
                  <span class="float-start">凶</span><span class="float-end">吉&nbsp;</span>
                </div>
                  </div>
                </div>
                <div class="row g-0" v-if="marriageSelectedGender">
                  <div class="col d-none"><span class="cn pillars">&nbsp;</span></div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">九星</span>
                  </div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">宫位</span>
                  </div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">八门</span>
                  </div>
                </div>
                <div class="row g-0" v-if="marriageSelectedGender">
                  <div class="col d-none"><span class="cn">男方</span></div>
                  <div class="col">
                    <span class="cn">{{ marriage[marriageSelectedGender].asker["星_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ marriage[marriageSelectedGender].asker["宫_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ marriage[marriageSelectedGender].asker["门_full"][1] }}门</span>
                  </div>
                </div>
                <div class="row g-0" v-if="marriageSelectedGender">
                  <div class="col d-none"><span class="cn">&nbsp;</span></div>
                  <div
                    class="col"
                    :class="interactionBorder(marriage[marriageSelectedGender].interaction['星'])"
                  >
                    <span>{{ marriage[marriageSelectedGender].interaction["星"] }}</span>
                  </div>
                  <div
                    class="col"
                    :class="interactionBorder(marriage[marriageSelectedGender].interaction['宫'])"
                  >
                    <span>{{ marriage[marriageSelectedGender].interaction["宫"] }}</span>
                  </div>
                  <div
                    class="col"
                    :class="interactionBorder(marriage[marriageSelectedGender].interaction['门'])"
                  >
                    <span>{{ marriage[marriageSelectedGender].interaction["门"] }}</span>
                  </div>
                </div>
                <div class="row g-0" v-if="marriageSelectedGender">
                  <div class="col d-none"><span class="cn">六合</span></div>
                  <div class="col">
                    <span class="cn">{{ marriage[marriageSelectedGender].outcome["星_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ marriage[marriageSelectedGender].outcome["宫_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ marriage[marriageSelectedGender].outcome["门_full"][1] }}门</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-学业" role="tabpanel">
            <template v-if="!educationOutcomeText">
            <div class="mb-1 text-start">
              <label class="form-label cn">预测：</label>
                  <div class="form-check form-check-inline cn">
                    <input class="form-check-input" type="radio" name="inlineRadioOptionsEdu" id="inlineRadioEdu1" value="考试" v-model="educationPredictionType">
                    <label class="form-check-label" for="inlineRadioEdu1">考试</label>
                  </div>
                  <div class="form-check form-check-inline cn">
                    <input class="form-check-input" type="radio" name="inlineRadioOptionsEdu" id="inlineRadioEdu2" value="升学" v-model="educationPredictionType">
                    <label class="form-check-label" for="inlineRadioEdu2">升学</label>
                  </div>
                </div>
              <div class="mb-1 text-start">
              <label class="form-label cn">测法：</label>
                  <div class="form-check form-check-inline cn">
                    <input class="form-check-input" type="radio" name="inlineRadioOptionsEduEdu" id="inlineRadioEdu3" value="select" v-model="educationSelectedAskerType">
                    <label class="form-check-label" for="inlineRadioEdu3">自选问者年干</label>
                  </div>
                  <div class="form-check form-check-inline cn">
                    <input class="form-check-input" type="radio" name="inlineRadioOptionsEduEdu" id="inlineRadioEdu4" value="hour" v-model="educationSelectedAskerType">
                    <label class="form-check-label" for="inlineRadioEdu4">孩子</label>
                  </div>
                </div>
              <div class="mb-1" v-if="educationSelectedAskerType == 'select'">
                  <label class="form-label cn">问者年干：</label>
                  <select
                    class="form-select form-select-sm cn"
                    v-model="educationSelected年干"
                  >
                    <option disabled value="">请选择</option>
                    <option value="庚">庚 (位数0)</option>
                    <option value="辛">辛 (位数1)</option>
                    <option value="壬">壬 (位数2)</option>
                    <option value="癸">癸 (位数3)</option>
                    <option value="甲子">甲子(鼠)=戊 (位数4)</option>
                    <option value="甲戌">甲戌(狗)=己 (位数4)</option>
                    <option value="甲申">甲申(猴)=庚 (位数4)</option>
                    <option value="甲午">甲午(马)=辛 (位数4)</option>
                    <option value="甲辰">甲辰(龙)=壬 (位数4)</option>
                    <option value="甲寅">甲寅(虎)=癸 (位数4)</option>
                    <option value="乙">乙 (位数5)</option>
                    <option value="丙">丙 (位数6)</option>
                    <option value="丁">丁 (位数7)</option>
                    <option value="戊">戊 (位数8)</option>
                    <option value="己">己 (位数9)</option>
                  </select>
                </div>
                <div class="mb-1">
                  <button type="button" class="btn btn-brown" @click='getPredictEducation'>预测</button>
                </div>
            </template>
            <template v-if="educationOutcomeText">
              <div class="text-center brown-text">
            <span class="en-r" v-if="qimen.predict.crisis">
              {{ educationOutcomeText }}
            </span>
            <br/>&nbsp;
            <div class="mb-1">
                  <button type="button" class="btn btn-brown" @click='redoPredictEducation'>再测</button>
                </div>
              
            </div>
            </template>
          </div>
          <div class="tab-pane fade" id="pills-危机" role="tabpanel">
            <div class="text-center brown-text">
            <span class="en-r" v-if="qimen.predict.crisis">
              {{ qimen.predict.crisis }}
            </span>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-生意" role="tabpanel">
            <div class="d-flex flew-row align-items-center">
              <div class="col-4">
                <div class="mb-1">
                  <label class="form-label cn">问者年干：</label>
                  <select
                    class="form-select form-select-sm cn"
                    v-model="selected年干"
                  >
                    <option disabled value="">请选择</option>
                    <option value="庚">庚 (位数0)</option>
                    <option value="辛">辛 (位数1)</option>
                    <option value="壬">壬 (位数2)</option>
                    <option value="癸">癸 (位数3)</option>
                    <option value="甲子">甲子(鼠)=戊 (位数4)</option>
                    <option value="甲戌">甲戌(狗)=己 (位数4)</option>
                    <option value="甲申">甲申(猴)=庚 (位数4)</option>
                    <option value="甲午">甲午(马)=辛 (位数4)</option>
                    <option value="甲辰">甲辰(龙)=壬 (位数4)</option>
                    <option value="甲寅">甲寅(虎)=癸 (位数4)</option>
                    <option value="乙">乙 (位数5)</option>
                    <option value="丙">丙 (位数6)</option>
                    <option value="丁">丁 (位数7)</option>
                    <option value="戊">戊 (位数8)</option>
                    <option value="己">己 (位数9)</option>
                  </select>
                </div>
                <div class="gauge mx-auto mt-2" v-if="selected年干">
                  <div class="gauge__body">
                    <div
                      class="gauge__fill"
                      :style="{
                        transform: `rotate(${interactionScore / 2}turn)`,
                      }"
                      :class="interactionScoreColor"
                    ></div>
                    <div class="gauge__cover">
                      {{ interactionScoreDisplay }}
                    </div>
                  </div>
                </div>
                <div class="gauge mx-auto gauge-label" v-if="selected年干">
                  <span>凶</span><span class="float-end">吉&nbsp;</span>
                </div>
              </div>
              <div class="col text-center">
                <div class="row g-0" v-if="selected年干">
                  <div class="col"><span class="cn pillars">&nbsp;</span></div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">九星</span>
                  </div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">宫位</span>
                  </div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">八门</span>
                  </div>
                </div>
                <div class="row g-0" v-if="asker">
                  <div class="col"><span class="cn">问者</span></div>
                  <div class="col">
                    <span class="cn">{{ asker["星_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ asker["宫_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ asker["门_full"][1] }}门</span>
                  </div>
                </div>
                <div class="row g-0" v-if="interaction">
                  <div class="col"><span class="cn">&nbsp;</span></div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['星'])"
                  >
                    <span>{{ interaction["星"] }}</span>
                  </div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['宫'])"
                  >
                    <span>{{ interaction["宫"] }}</span>
                  </div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['门'])"
                  >
                    <span>{{ interaction["门"] }}</span>
                  </div>
                </div>
                <div class="row g-0" v-if="outcome">
                  <div class="col"><span class="cn">结果</span></div>
                  <div class="col">
                    <span class="cn">{{ outcome["星_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ outcome["宫_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ outcome["门_full"][1] }}门</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-股票" role="tabpanel">
            <div class="d-flex flew-row align-items-end" v-if="stocksStatus == 'new'">
              <div class="col">
                <div class="mb-1">
                  <label class="form-label cn">问者年干：</label>
                  <select
                    class="form-select form-select-sm cn"
                    v-model="selected年干"
                  >
                    <option disabled value="">请选择</option>
                    <option value="庚">庚 (位数0)</option>
                    <option value="辛">辛 (位数1)</option>
                    <option value="壬">壬 (位数2)</option>
                    <option value="癸">癸 (位数3)</option>
                    <option value="甲子">甲子(鼠)=戊 (位数4)</option>
                    <option value="甲戌">甲戌(狗)=己 (位数4)</option>
                    <option value="甲申">甲申(猴)=庚 (位数4)</option>
                    <option value="甲午">甲午(马)=辛 (位数4)</option>
                    <option value="甲辰">甲辰(龙)=壬 (位数4)</option>
                    <option value="甲寅">甲寅(虎)=癸 (位数4)</option>
                    <option value="乙">乙 (位数5)</option>
                    <option value="丙">丙 (位数6)</option>
                    <option value="丁">丁 (位数7)</option>
                    <option value="戊">戊 (位数8)</option>
                    <option value="己">己 (位数9)</option>
                  </select>
                </div>
              </div>
              <div class="col px-3">
                <div class="mb-1">
                  <label class="form-label cn" for="numberInput">数目：</label>
                  <input inputmode="numeric" pattern="[0-9]*" type="text" class="form-control form-control-sm brown-text" id="numberInput" v-model='givenNumber' autocomplete="off">
                </div>
              </div>
              <div class="col">
                <div class="mb-1">
                  <button type="button" class="btn btn-brown" @click='getPredictStocks'>预测</button>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center brown-text" v-if="stocksStatus == 'loading'">
              <span class="en-r brown-text">预测中...</span>
              <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
            <span v-if="stocksStatus == 'results'">
            <div class="row pb-3">              
              <div class="col en-r brown-text text-center">
                年干: {{ stocksOutcome.queryStringParameters.年干 }} 数目: {{ stocksOutcome.queryStringParameters.givenNumber }}<br/>
                预测结果（{{ dispDateString(stocksOutcome.queryStringParameters.time) }} 为准）
              </div>
            </div>            
            <div class="row" v-if="stocksOutcome.predict.stocks.buyDecision == '可以'">
              <div class="col en-r text-center">
                <span class="px-3 py-2 bg-success text-white">可以</span>
              </div>
            </div>
            <div class="row" v-if="stocksOutcome.predict.stocks.buyDecision == '不可以'">
              <div class="col en-r text-center">
                <span class="px-3 py-2 bg-danger text-white">不可以</span>
              </div>
            </div>
            <div class="row" v-if="stocksOutcome.predict.stocks.buyDecision == '你决定'">
              <div class="col en-r text-center">
                <span class="px-3 py-2 bg-warning text-dark">你决定 (1/2)</span>
              </div>
            </div>
            <div class="row">
              <div class="col en-r pt-3 text-center">
                <button type="button" class="btn btn-brown" @click='redoPredictStocks'>再测</button>
              </div>
              </div>
            </span>
          </div>
          <div class="tab-pane fade" id="pills-盲选" role="tabpanel">
            <div class="d-flex flew-row align-items-end" v-if="randomPickStatus == 'new'">
              <div class="col">
                <div class="mb-1">
                  <label class="form-label cn">问者年干：</label>
                  <select
                    class="form-select form-select-sm cn"
                    v-model="selected年干"
                  >
                    <option disabled value="">请选择</option>
                    <option value="庚">庚 (位数0)</option>
                    <option value="辛">辛 (位数1)</option>
                    <option value="壬">壬 (位数2)</option>
                    <option value="癸">癸 (位数3)</option>
                    <option value="甲子">甲子(鼠)=戊 (位数4)</option>
                    <option value="甲戌">甲戌(狗)=己 (位数4)</option>
                    <option value="甲申">甲申(猴)=庚 (位数4)</option>
                    <option value="甲午">甲午(马)=辛 (位数4)</option>
                    <option value="甲辰">甲辰(龙)=壬 (位数4)</option>
                    <option value="甲寅">甲寅(虎)=癸 (位数4)</option>
                    <option value="乙">乙 (位数5)</option>
                    <option value="丙">丙 (位数6)</option>
                    <option value="丁">丁 (位数7)</option>
                    <option value="戊">戊 (位数8)</option>
                    <option value="己">己 (位数9)</option>
                  </select>
                </div>
              </div>
              <div class="col px-3">
                <div class="mb-1">
                  <label class="form-label cn" for="numberInput">数目：</label>
                  <input inputmode="numeric" pattern="[0-9]*" type="text" class="form-control form-control-sm brown-text" id="numberInput" v-model='givenNumber' autocomplete="off">
                </div>
              </div>
              <div class="col">
                <div class="mb-1">
                  <button type="button" class="btn btn-brown" @click='getPredictRandom'>预测</button>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center brown-text text-center" v-if="randomPickStatus == 'error'">
              <div class="col-12"><span class="en-r brown-text">不可测</span></div>
            </div>
            <div class="d-flex align-items-center brown-text text-center" v-if="randomPickStatus == 'error'">
              <div class="col-12"><button type="button" class="btn btn-brown" @click='redoPredictRandom'>再测</button></div>
            </div>
            <span v-if="randomPickStatus == 'results'">
            <div class="d-flex flew-row align-items-center">
              <div class="col-4">
                <div class="gauge mx-auto">
                  <div class="gauge__body">
                    <div
                      class="gauge__fill"
                      :style="{
                        transform: `rotate(${interactionScore / 2}turn)`,
                      }"
                      :class="interactionScoreColor"
                    ></div>
                    <div class="gauge__cover">
                      {{ interactionScoreDisplay }}
                    </div>
                  </div>
                </div>
                <div class="gauge mx-auto gauge-label">
                  <span>凶</span><span class="float-end">吉&nbsp;</span>
                </div>
              </div>
              <div class="col text-center">
                <div class="row g-0">
                  <div class="col"><span class="cn pillars">&nbsp;</span></div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">九星</span>
                  </div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">宫位</span>
                  </div>
                  <div class="col border light-yellow">
                    <span class="cn pillars">八门</span>
                  </div>
                </div>
                <div class="row g-0" v-if="asker">
                  <div class="col"><span class="cn">问者</span></div>
                  <div class="col">
                    <span class="cn">{{ asker["星_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ asker["宫_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ asker["门_full"][1] }}门</span>
                  </div>
                </div>
                <div class="row g-0" v-if="interaction">
                  <div class="col"><span class="cn">&nbsp;</span></div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['星'])"
                  >
                    <span>{{ interaction["星"] }}</span>
                  </div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['宫'])"
                  >
                    <span>{{ interaction["宫"] }}</span>
                  </div>
                  <div
                    class="col"
                    :class="interactionBorder(interaction['门'])"
                  >
                    <span>{{ interaction["门"] }}</span>
                  </div>
                </div>
                <div class="row g-0" v-if="outcome">
                  <div class="col"><span class="cn">结果</span></div>
                  <div class="col">
                    <span class="cn">{{ outcome["星_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ outcome["宫_full"] }}</span>
                  </div>
                  <div class="col">
                    <span class="cn">{{ outcome["门_full"][1] }}门</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col en-r pt-3 text-center">
                <button type="button" class="btn btn-brown" @click='redoPredictRandom'>再测</button>
              </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row justify-content-center pt-1 pb-4">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
        <div class="row pb-2">
          <div class="col en-r brown-text text-center" style="font-size: 0.8rem">{{ qimen.timingText }}
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-1 text-center border-top border-start gx-0 en-r 木 brown-text">
            <div class="my-1">
              <span
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="<div class='row'><div class='col en-r'><div class='tp-木 text-center my-1 ms-auto'>木</div></div></div>"
              >
              SE
              </span>
            </div>
          </div>
          <!-- 巳 -->
          <div class="col border-top 木 gx-1 gx-md-2">
            <div class="d-flex flex-row justify-content-start">
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[0].includes('巳空')"
                title="巳空" data-bs-toggle="tooltip"
              >
                空
              </div>
              <div class="my-1">&nbsp;</div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[0].includes('巳马')"
                title="巳马" data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
          <!-- 午 -->
          <div class="col border-top en-r 火 gx-1 gx-md-2">
            <div
              class="d-flex flex-row justify-content-center align-items-center"
            >
              <div
                class="cn kong text-center my-1 me-2"
                v-if="qimen.奇门时盘.空马[1].includes('午空')"
                title="午空" data-bs-toggle="tooltip"
              >
                空
              </div>
              <div class="my-1 brown-text">
                <span
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="<div class='row'><div class='col en-r'><div class='tp-火 text-center my-1 ms-auto'>火</div></div></div>"
              >
              S
              </span>
              </div>
              <div
                class="cn kong text-center my-1 ms-2"
                v-if="qimen.奇门时盘.空马[1].includes('午马')"
                title="午马" data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
          <!-- 未 -->
          <div class="col border-top 土 gx-1 gx-md-2">
            <div class="d-flex flex-row justify-content-end">
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[2].includes('未空')"
                title="未空" data-bs-toggle="tooltip"
              >
                空
              </div>
              <div class="my-1">&nbsp;</div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[2].includes('未马')"
                title="未马" data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
          <div class="col-1 text-center border-top border-end gx-0 en-r 土 brown-text">
            <div class="my-1">
              <span
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="<div class='row'><div class='col en-r'><div class='tp-土 text-center my-1 ms-auto'>土</div></div></div>"
              >
              SW
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- 辰 -->
          <div class="col-1 text-center border-start gx-0 木">
            <div class="d-flex flex-column align-items-center">
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[0].includes('辰空')"
                title="辰空" data-bs-toggle="tooltip"
              >
                空
              </div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[0].includes('辰马')"
                title="辰马" data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
          <div
            class="col border gx-1 gx-md-2"
            :class="{
              pink: qimen.奇门时盘.局[1] == 'i',
              'border-danger': redBorderMe(0),
              'border-primary': blueBorderMe(0) || blueBorderMeTwo(0),
              'border-2': redBorderMe(0) || blueBorderMe(0) || blueBorderMeTwo(0),
            }"
          >
            <QimenPalace v-if="!this.qimen.formationUser" :pos="0" :qimen="qimen" :outcomePalace="redBorderMe(0)" :askerPalace="blueBorderMe(0) || blueBorderMeTwo(0)" :calendar="calendar(0)" :redBorderStem="redBorderStem" />
            <QimenPalaceWithFormationForPredict v-else :pos="0" :palaceFormations="palaceFormations(0)" :qimen="qimen" :outcomePalace="redBorderMe(0)" :askerPalace="blueBorderMe(0) || blueBorderMeTwo(0)" :calendar="calendar(0)" :redBorderStem="redBorderStem" />
          </div>
          <div
            class="col border gx-1 gx-md-2"
            :class="{
              pink: qimen.奇门时盘.局[1] == 'a',
              'border-danger': redBorderMe(1),
              'border-primary': blueBorderMe(1) || blueBorderMeTwo(1),
              'border-2': redBorderMe(1) || blueBorderMe(1) || blueBorderMeTwo(1),
            }"
          >
            <QimenPalace v-if="!this.qimen.formationUser" :pos="1" :qimen="qimen" :outcomePalace="redBorderMe(1)" :askerPalace="blueBorderMe(1) || blueBorderMeTwo(1)" :calendar="calendar(1)" :redBorderStem="redBorderStem" />
            <QimenPalaceWithFormationForPredict v-else :pos="1" :palaceFormations="palaceFormations(1)" :qimen="qimen" :outcomePalace="redBorderMe(1)" :askerPalace="blueBorderMe(1) || blueBorderMeTwo(1)" :calendar="calendar(1)" :redBorderStem="redBorderStem" />
          </div>
          <div
            class="col border gx-1 gx-md-2"
            :class="{
              pink: qimen.奇门时盘.局[1] == 'a',
              'border-danger': redBorderMe(2),
              'border-primary': blueBorderMe(2) || blueBorderMeTwo(2),
              'border-2': redBorderMe(2) || blueBorderMe(2) || blueBorderMeTwo(2),
            }"
          >
            <QimenPalace v-if="!this.qimen.formationUser" :pos="2" :qimen="qimen" :outcomePalace="redBorderMe(2)" :askerPalace="blueBorderMe(2) || blueBorderMeTwo(2)" :calendar="calendar(2)" :redBorderStem="redBorderStem" />
            <QimenPalaceWithFormationForPredict v-else :pos="2" :palaceFormations="palaceFormations(2)" :qimen="qimen" :outcomePalace="redBorderMe(2)" :askerPalace="blueBorderMe(2) || blueBorderMeTwo(2)" :calendar="calendar(2)" :redBorderStem="redBorderStem" />
          </div>
          <!-- 申 -->
          <div class="col-1 text-center border-end gx-0 土">
            <div class="d-flex flex-column align-items-center">
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[2].includes('申空')"
                title="申空" data-bs-toggle="tooltip"
              >
                空
              </div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[2].includes('申马')"
                title="申马" data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- 卯 -->
          <div class="col-1 text-center border-start gx-0 木">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
                h-100
              "
            >
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[3].includes('卯空')"
                title="卯空" data-bs-toggle="tooltip"
              >
                空
              </div>
              <div class="en-r brown-text">
                <span
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="<div class='row'><div class='col en-r'><div class='tp-木 text-center my-1 ms-auto'>木</div></div></div>"
              >
              E
              </span>
              </div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[3].includes('卯马')"
                title="卯马" data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
          <div
            class="col border gx-1 gx-md-2"
            :class="{
              pink: qimen.奇门时盘.局[1] == 'i',
              'border-danger': redBorderMe(3),
              'border-primary': blueBorderMe(3) || blueBorderMeTwo(3),
              'border-2': redBorderMe(3) || blueBorderMe(3) || blueBorderMeTwo(3),
            }"
          >
            <QimenPalace v-if="!this.qimen.formationUser" :pos="3" :qimen="qimen" :outcomePalace="redBorderMe(3)" :askerPalace="blueBorderMe(3) || blueBorderMeTwo(3)" :calendar="calendar(3)" :redBorderStem="redBorderStem" />
            <QimenPalaceWithFormationForPredict v-else :pos="3" :palaceFormations="palaceFormations(3)" :qimen="qimen" :outcomePalace="redBorderMe(3)" :askerPalace="blueBorderMe(3) || blueBorderMeTwo(3)" :calendar="calendar(3)" :redBorderStem="redBorderStem" />
          </div>
          <div class="col border gx-1 gx-md-2">
            <div class="row pb-2">
              <div class="col cn pe-0">&nbsp;</div>
              <div class="col flex-grow-1 text-end cn">
                {{ qimen["奇门时盘"]["地盘"][4] }}
              </div>
            </div>
            <div class="row">
              <div class="col text-center cn" style="font-size: 1.6rem">
                &nbsp;
              </div>
            </div>
            <div class="row pt-2">
              <div class="col cn">&nbsp;</div>
              <div class="col text-end cn">
                {{ qimen["奇门时盘"]["地盘"][4] }}
              </div>
            </div>
          </div>
          <div
            class="col border gx-1 gx-md-2"
            :class="{
              pink: qimen.奇门时盘.局[1] == 'a',
              'border-danger': redBorderMe(5),
              'border-primary': blueBorderMe(5) || blueBorderMeTwo(5),
              'border-2': redBorderMe(5) || blueBorderMe(5) || blueBorderMeTwo(5),
            }"
          >
            <QimenPalace v-if="!this.qimen.formationUser" :pos="5" :qimen="qimen" :outcomePalace="redBorderMe(5)" :askerPalace="blueBorderMe(5) || blueBorderMeTwo(5)" :calendar="calendar(5)" :redBorderStem="redBorderStem" />
            <QimenPalaceWithFormationForPredict v-else :pos="5" :palaceFormations="palaceFormations(5)" :qimen="qimen" :outcomePalace="redBorderMe(5)" :askerPalace="blueBorderMe(5) || blueBorderMeTwo(5)" :calendar="calendar(5)" :redBorderStem="redBorderStem" />
          </div>
          <!-- 酉 -->
          <div class="col-1 text-center border-end gx-0 金">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
                h-100
              "
            >
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[5].includes('酉空')"
                title="酉空" data-bs-toggle="tooltip"
              >
                空
              </div>
              <div class="en-r brown-text">
                <span
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="<div class='row'><div class='col en-r'><div class='tp-金 text-center my-1 ms-auto'>金</div></div></div>"
              >
              W
              </span>
              </div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[5].includes('酉马')"
                title="酉马" data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- 寅 -->
          <div class="col-1 text-center border-start gx-0 土">
            <div
              class="
                d-flex
                flex-column-reverse
                justify-content-start
                align-items-center
                h-100
              "
            >
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[6].includes('寅空')"
                title="寅空" data-bs-toggle="tooltip"
              >
                空
              </div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[6].includes('寅马')"
                title="寅马" data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
          <div
            class="col border gx-1 gx-md-2"
            :class="{
              pink: qimen.奇门时盘.局[1] == 'i',
              'border-danger': redBorderMe(6),
              'border-primary': blueBorderMe(6) || blueBorderMeTwo(6),
              'border-2': redBorderMe(6) || blueBorderMe(6) || blueBorderMeTwo(6),
            }"
          >
            <QimenPalace v-if="!this.qimen.formationUser" :pos="6" :qimen="qimen" :outcomePalace="redBorderMe(6)" :askerPalace="blueBorderMe(6) || blueBorderMeTwo(6)" :calendar="calendar(6)" :redBorderStem="redBorderStem" />
            <QimenPalaceWithFormationForPredict v-else :pos="6" :palaceFormations="palaceFormations(6)" :qimen="qimen" :outcomePalace="redBorderMe(6)" :askerPalace="blueBorderMe(6) || blueBorderMeTwo(6)" :calendar="calendar(6)" :redBorderStem="redBorderStem" />
          </div>
          <div
            class="col border gx-1 gx-md-2"
            :class="{
              pink: qimen.奇门时盘.局[1] == 'i',
              'border-danger': redBorderMe(7),
              'border-primary': blueBorderMe(7) || blueBorderMeTwo(7),
              'border-2': redBorderMe(7) || blueBorderMe(7) || blueBorderMeTwo(7),
            }"
          >
            <QimenPalace v-if="!this.qimen.formationUser" :pos="7" :qimen="qimen" :outcomePalace="redBorderMe(7)" :askerPalace="blueBorderMe(7) || blueBorderMeTwo(7)" :calendar="calendar(7)" :redBorderStem="redBorderStem" />
            <QimenPalaceWithFormationForPredict v-else :pos="7" :palaceFormations="palaceFormations(7)" :qimen="qimen" :outcomePalace="redBorderMe(7)" :askerPalace="blueBorderMe(7) || blueBorderMeTwo(7)" :calendar="calendar(7)" :redBorderStem="redBorderStem" />
          </div>
          <div
            class="col border gx-1 gx-md-2"
            :class="{
              pink: qimen.奇门时盘.局[1] == 'a',
              'border-danger': redBorderMe(8),
              'border-primary': blueBorderMe(8) || blueBorderMeTwo(8),
              'border-2': redBorderMe(8) || blueBorderMe(8) || blueBorderMeTwo(8),
            }"
          >
            <QimenPalace v-if="!this.qimen.formationUser" :pos="8" :qimen="qimen" :outcomePalace="redBorderMe(8)" :askerPalace="blueBorderMe(8) || blueBorderMeTwo(8)" :calendar="calendar(8)" :redBorderStem="redBorderStem" />
            <QimenPalaceWithFormationForPredict v-else :pos="8" :palaceFormations="palaceFormations(8)" :qimen="qimen" :outcomePalace="redBorderMe(8)" :askerPalace="blueBorderMe(8) || blueBorderMeTwo(8)" :calendar="calendar(8)" :redBorderStem="redBorderStem" />
          </div>
          <!-- 戌 -->
          <div class="col-1 text-center border-end gx-0 金">
            <div
              class="
                d-flex
                flex-column-reverse
                justify-content-start
                align-items-center
                h-100
              "
            >
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[8].includes('戌空')"
                title="戌空" data-bs-toggle="tooltip"
              >
                空
              </div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[8].includes('戌马')"
                title="戌马" data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-1 text-center border-start border-bottom gx-0 en-r 土 brown-text"
          >
            <div class="my-1">
              <span
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="<div class='row'><div class='col en-r'><div class='tp-土 text-center my-1 ms-auto'>土</div></div></div>"
              >
              NE
              </span>
            </div>
          </div>
          <!-- 丑 -->
          <div class="col border-bottom 土 gx-1 gx-md-2">
            <div class="d-flex flex-row justify-content-start">
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[6].includes('丑空')"
                title="丑空" data-bs-toggle="tooltip"
              >
                空
              </div>
              <div class="my-1">&nbsp;</div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[6].includes('丑马')"
                title="丑马" data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
          <!-- 子 -->
          <div class="col text-center border-bottom en-r 水 gx-1 gx-md-2">
            <div
              class="d-flex flex-row justify-content-center align-items-center"
            >
              <div
                class="cn kong text-center my-1 me-2"
                v-if="qimen.奇门时盘.空马[7].includes('子空')"
                title="子空" data-bs-toggle="tooltip"
              >
                空
              </div>
              <div class="my-1 brown-text">
                <span
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="<div class='row'><div class='col en-r'><div class='tp-水 text-center my-1 ms-auto'>水</div></div></div>"
              >
              N
              </span>
              </div>
              <div
                class="cn kong text-center my-1 ms-2"
                v-if="qimen.奇门时盘.空马[7].includes('子马')"
                title="子马" data-bs-toggle="tooltip"
              >
                马
              </div>
            </div>
          </div>
          <!-- 亥 -->
          <div class="col border-bottom text-end 金 gx-1 gx-md-2">
            <div class="d-flex flex-row justify-content-end">
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[8].includes('亥马')"
                title="亥马" data-bs-toggle="tooltip"
              >
                马
              </div>
              <div class="my-1">&nbsp;</div>
              <div
                class="cn kong text-center my-1"
                v-if="qimen.奇门时盘.空马[8].includes('亥空')"
                title="亥空" data-bs-toggle="tooltip"
              >
                空
              </div>
            </div>
          </div>
          <div class="col-1 text-center border-bottom border-end gx-0 en-r 金 brown-text">
            <div class="my-1">
              <span
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="<div class='row'><div class='col en-r'><div class='tp-金 text-center my-1 ms-auto'>金</div></div></div>"
              >
              NW
              </span>
            </div>
          </div>
        </div>
        <div class="row py-2">
          <div class="col en-r brown-text text-center" style="font-size: 0.8rem">
            <div class="row pt-2">
              <div class="col border light-yellow"><span class="cn pillars">值符</span></div>
              <div class="col-auto border"><span class="cn pillars">{{ qimen.奇门时盘.天盘值符stream }}{{ qimen.奇门时盘.天盘值符 }} {{ qimen.奇门时盘.九星lead }} <span class="en-r" style="font-size: 0.8rem;">({{ positions[qimen.奇门时盘.九星.indexOf(qimen.奇门时盘.九星lead)] }})</span></span></div>
              <div class="col border light-yellow"><span class="cn pillars">值使</span></div>
              <div class="col-auto border"><span class="cn pillars">{{ qimen.奇门时盘.八门直使[1] }}门 <span class="en-r" style="font-size: 0.8rem;">({{ positions[qimen.奇门时盘.八门.indexOf(qimen.奇门时盘.八门直使)] }})</span></span></div>
            </div>
          </div>
        </div>
      </div>
    </div>    
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-xl modal-fullscreen-xl-down
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Choose time
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form class="border px-3 py-3" novalidate>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label pt-0"
                >Date</label
              >
              <div class="col-sm-3">
                <select
                  class="form-select"
                  v-model="day"
                  :class="[
                    !day && turnOnValidators ? 'is-invalid' : '',
                    validDate ? '' : 'is-invalid',
                  ]"
                >
                  <option selected value="null">Select day 日</option>
                  <option
                    v-for="day in days"
                    :value="day.label"
                    :key="day.label"
                  >
                    {{ day.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <select
                  class="form-select"
                  v-model="month"
                  :class="[
                    !month && turnOnValidators ? 'is-invalid' : '',
                    validDate ? '' : 'is-invalid',
                  ]"
                >
                  <option selected value="null">Select month 月</option>
                  <option
                    v-for="month in months"
                    :value="month.value"
                    :key="month.value"
                  >
                    {{ month.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-4">
                <select
                  class="form-select"
                  v-model="year"
                  :class="[
                    !year && turnOnValidators ? 'is-invalid' : '',
                    validDate ? '' : 'is-invalid',
                  ]"
                >
                  <option selected value="null">Select year 年</option>
                  <option
                    v-for="year in years"
                    :value="year.label"
                    :key="year.label"
                  >
                    {{ year.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label pt-0"
                >Time</label
              >
              <div class="col-sm-3">
                <select
                  class="form-select"
                  v-model="hour"
                  :class="[
                    !hour && turnOnValidators,
                  ]"
                >
                  <option selected value="null">Select hour 时</option>
                  <option
                    v-for="hour in hours"
                    :value="hour.label"
                    :key="hour.label"
                  >
                    {{ hour.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <select
                  class="form-select"
                  v-model="minute"
                  :class="[
                    !minute && turnOnValidators,
                  ]"
                >
                  <option selected value="null">Select minute 分</option>
                  <option
                    v-for="minute in minutes"
                    :value="minute.label"
                    :key="minute.value"
                  >
                    {{ minute.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-4">
                <select
                  class="form-select"
                  v-model="selectedTimeOfDay"
                  :class="[
                    !selectedTimeOfDay && turnOnValidators 
                  ]"
                >
                  <option selected value="null">Select AM/PM</option>
                  <option
                    v-for="t in timeOfDay"
                    :value="t.label"
                    :key="t.label"
                  >
                    {{ t.label }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="processHourlyInput">
            Generate
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import QimenPalace from "@/components/QimenPalace.vue";
import QimenPalaceWithFormationForPredict from "../components/QimenPalaceWithFormationForPredict.vue";
import { Auth, API } from "aws-amplify";
import { DateTime } from "luxon";
import { toNumber, isInteger } from "lodash";
import { Tooltip, Modal } from "bootstrap";

const inclusiveRange = (start, end, step) => {
  return Array.from(
    Array.from(Array(Math.ceil((end - start + 1) / step)).keys()),
    (x) => {
      return { label: start + x * step };
    }
  );
};

const 六十甲子表 = {
  甲子: { stream: "甲子", leadStem: "戊" },
  甲戌: { stream: "甲戌", leadStem: "己" },
  甲申: { stream: "甲申", leadStem: "庚" },
  甲午: { stream: "甲午", leadStem: "辛" },
  甲辰: { stream: "甲辰", leadStem: "壬" },
  甲寅: { stream: "甲寅", leadStem: "癸" },
};
const 洛书 = [4, 9, 2, 3, 5, 7, 8, 1, 6];
const 入墓 = [
    ["辛", "壬"],
    [],
    ["乙", "癸"],
    [],
    [],
    [],
    ["丁", "己", "庚"],
    [],
    ["乙", "丙", "戊"]
  ]

let hourlyModal = null;

export default {
  name: "QimenPredict",
  components: {
    QimenPalace,
    QimenPalaceWithFormationForPredict
},
  data: () => ({
    positions: ["SE", "S", "SW", "E", "C", "W", "NE", "N", "NW"],
    qimen: null,
    outcomeToggle: "",
    predictionMode: "",
    asker: null,
    interaction: null,
    interactionScore: 0.5,
    interactionScoreDisplay: 0,
    outcome: null,
    selected年干: "",
    selected年干outcome: "",
    redBorderStem: "",
    blueBorderStem: "",
    blueBorderStemTwo: "",
    marriage: {},
    givenNumber: null,
    stocksStatus: 'new', // new, loading, results
    stocksOutcome: null,
    marriageSelectedGender: "",
    randomPickStatus: 'new', // new, loading, results, error
    sicknessPredictionType: "",
    sicknessDisplayText: "",
    educationPredictionType: "", // results考试, school升学
    educationSelectedAskerType: "", // own selection, hour stem
    educationSelected年干: "",
    educationOutcomeText: "",
    days: Array.from(Array(31).keys(), (n) => {
      return { label: n + 1 };
    }),
    months: [
      { label: "Jan (1)", value: 1 },
      { label: "Feb (2)", value: 2 },
      { label: "Mar (3)", value: 3 },
      { label: "Apr (4)", value: 4 },
      { label: "May (5)", value: 5 },
      { label: "Jun (6)", value: 6 },
      { label: "Jul (7)", value: 7 },
      { label: "Aug (8)", value: 8 },
      { label: "Sep (9)", value: 9 },
      { label: "Oct (10)", value: 10 },
      { label: "Nov (11)", value: 11 },
      { label: "Dec (12)", value: 12 },
    ],
    years: inclusiveRange(2025, 1918, -1),
    hours: Array.from(Array(12).keys(), (n) => {
      return { label: n + 1 };
    }),
    minutes: Array.from(Array(60).keys(), (n) => {
      return { label: String(n).padStart(2, "0") };
    }),
    timeOfDay: Array.from(["AM", "PM"], (n) => {
      return { label: n };
    }),
    day: null,
    month: null,
    year: null,
    hour: null,
    minute: null,
    selectedTimeOfDay: null,
    timestamp: null,
    turnOnValidators: true,
  }),
  watch: {
    predictionMode(newMode) {
      this.selected年干 = "";
      this.selected年干outcome = "";
      this.redBorderStem = "";
      this.blueBorderStem = "";
      this.blueBorderStemTwo = "";
      this.marriage = {};
      this.marriageSelectedGender = "";
      this.sicknessPredictionType = "";
      this.sicknessDisplayText = "";
      this.educationPredictionType = "";
      this.educationSelectedAskerType = "";
      this.educationSelected年干 = "";
      this.educationOutcomeText = "";
      if (newMode == "基本") {
        this.outcomeToggle = "hourOutcome";
        this.redBorderStem = this.qimen.predict.outcome;
        this.asker =
          this.qimen.predict.interactions[this.qimen.predict.asker].me;
        this.interaction =
          this.qimen.predict.interactions[this.qimen.predict.asker].outcome[
            this.qimen.predict.outcome
          ];
        this.outcome =
          this.qimen.predict.interactions[this.qimen.predict.outcome].me;

        this.interactionScoreDisplay = this.interactionScoreDisplayText(this.interaction.score);

        // scores range from -8 to 8. 0 should be 0.5 mark
        if (this.interaction.score >= 0) {
          this.interactionScore = 0.5 + (this.interaction.score / 9) * 0.5;
        } else {
          this.interactionScore = ((9 + this.interaction.score) / 9) * 0.5;
        }
        this.blueBorderStem = this.qimen.predict.asker;
      } else if (newMode == "年干") {
        this.outcomeToggle = "hourOutcome";
        this.redBorderStem = this.qimen.predict.outcome;
        this.asker = null;
        this.interaction = null;
        this.interactionScore = 0.5;
        this.interactionScoreDisplay = 0;
        this.outcome = null;
      } else if (newMode == "比较") {
        this.outcomeToggle = "manualOutcome";
        this.asker = null;
        this.interaction = null;
        this.interactionScore = 0.5;
        this.interactionScoreDisplay = 0;
        this.outcome = null;
      } else if (newMode == "事业") {
        this.outcomeToggle = "manualOutcome";
        this.asker = null;
        this.interaction = null;
        this.interactionScore = 0.5;
        this.interactionScoreDisplay = 0;
        this.outcome = null;
      } else if (newMode == "生意") {
        this.outcomeToggle = "manualOutcome";
        this.asker = null;
        this.interaction = null;
        this.interactionScore = 0.5;
        this.interactionScoreDisplay = 0;
        this.outcome = null;
      } else if (newMode == "婚姻") {
        this.outcomeToggle = "manualOutcome";
        this.asker = null;
        this.interaction = null;
        this.interactionScore = 0.5;
        this.interactionScoreDisplay = 0;
        this.outcome = null;

        this.marriage.vs = {}
       this.marriage.vs.男方 = {
         asker: this.qimen.predict.interactions['庚'].me,
         interaction: this.qimen.predict.interactions['庚'].outcome[
            '乙'
          ],
          outcome: this.qimen.predict.interactions['乙'].me,
       }
       this.marriage.vs.男方.interactionScoreDisplay = this.interactionScoreDisplayText(this.marriage.vs.男方.interaction.score);
       if (this.marriage.vs.男方.interaction.score >= 0) {
          this.marriage.vs.男方.interactionScore = 0.5 + (this.marriage.vs.男方.interaction.score / 9) * 0.5;
        } else {
          this.marriage.vs.男方.interactionScore = ((9 + this.marriage.vs.男方.interaction.score) / 9) * 0.5;
        }        
        this.marriage.vs.男方.interactionScoreColor = this.computeColor(this.marriage.vs.男方.interaction.score);

      this.marriage.vs.女方 = {
         asker: this.qimen.predict.interactions['乙'].me,
         interaction: this.qimen.predict.interactions['乙'].outcome[
            '庚'
          ],
          outcome: this.qimen.predict.interactions['庚'].me,
       }
       this.marriage.vs.女方.interactionScoreDisplay = this.interactionScoreDisplayText(this.marriage.vs.女方.interaction.score);
       if (this.marriage.vs.女方.interaction.score >= 0) {
          this.marriage.vs.女方.interactionScore = 0.5 + (this.marriage.vs.女方.interaction.score / 9) * 0.5;
        } else {
          this.marriage.vs.女方.interactionScore = ((9 + this.marriage.vs.女方.interaction.score) / 9) * 0.5;
        }        
        this.marriage.vs.女方.interactionScoreColor = this.computeColor(this.marriage.vs.女方.interaction.score);
      
      this.marriage.男方 = {
         asker: this.qimen.predict.interactions['庚'].me,
         interaction: this.qimen.predict.interactions['庚'].outcome[
            this.qimen.predict.relationship
          ],
          outcome: this.qimen.predict.interactions[this.qimen.predict.relationship].me,
       }
       this.marriage.男方.interactionScoreDisplay = this.interactionScoreDisplayText(this.marriage.男方.interaction.score);
       if (this.marriage.男方.interaction.score >= 0) {
          this.marriage.男方.interactionScore = 0.5 + (this.marriage.男方.interaction.score / 9) * 0.5;
        } else {
          this.marriage.男方.interactionScore = ((9 + this.marriage.男方.interaction.score) / 9) * 0.5;
        }
      this.marriage.男方.interactionScoreColor = this.computeColor(this.marriage.男方.interaction.score);
      this.marriage.女方 = {
         asker: this.qimen.predict.interactions['乙'].me,
         interaction: this.qimen.predict.interactions['乙'].outcome[
            this.qimen.predict.relationship
          ],
          outcome: this.qimen.predict.interactions[this.qimen.predict.relationship].me,
       }
       this.marriage.女方.interactionScoreDisplay = this.interactionScoreDisplayText(this.marriage.女方.interaction.score);
       if (this.marriage.女方.interaction.score >= 0) {
          this.marriage.女方.interactionScore = 0.5 + (this.marriage.女方.interaction.score / 9) * 0.5;
        } else {
          this.marriage.女方.interactionScore = ((9 + this.marriage.女方.interaction.score) / 9) * 0.5;
        }        
        this.marriage.女方.interactionScoreColor = this.computeColor(this.marriage.女方.interaction.score);
       this.redBorderStem = this.qimen.predict.relationship;
       this.blueBorderStem = '乙';
       this.blueBorderStemTwo = '庚';
      } else if (newMode == "危机") {
        this.outcomeToggle = "manualOutcome";
        this.redBorderStem = '庚';
      } else if (newMode == "股票") {
        this.outcomeToggle = "manualOutcome";
        this.stocksStatus = 'new';
        this.givenNumber = null;
        this.stocksOutcome = null;
      } else if (newMode == "盲选") {
        this.outcomeToggle = "manualOutcome";
        this.randomPickStatus = 'new';
        this.givenNumber = null;
      } else if (newMode == "疾病") {
        this.outcomeToggle = "manualOutcome";
        this.redBorderStem = this.qimen.predict.sickness
      } else if (newMode == "学业") {
        this.outcomeToggle = "manualOutcome";
        
      }
    },
    selected年干(new干) {
      if (this.predictionMode == "年干" && new干) {
        const new干resolved = 六十甲子表[new干]
          ? 六十甲子表[new干]["leadStem"]
          : new干;
        this.outcomeToggle = "hourOutcome";
        this.asker = this.qimen.predict.interactions[new干resolved].me;
        this.interaction =
          this.qimen.predict.interactions[new干resolved].outcome[
            this.qimen.predict.outcome
          ];
        this.outcome =
          this.qimen.predict.interactions[this.qimen.predict.outcome].me;

        this.interactionScoreDisplay = this.interactionScoreDisplayText(this.interaction.score);

        // scores range from -8 to 8. 0 should be 0.5 mark
        if (this.interaction.score >= 0) {
          this.interactionScore = 0.5 + (this.interaction.score / 9) * 0.5;
        } else {
          this.interactionScore = ((9 + this.interaction.score) / 9) * 0.5;
        }
        this.blueBorderStem = new干resolved;
      } else if (
        this.predictionMode == "比较" &&
        new干 &&
        this.selected年干outcome
      ) {
        const new干resolved = 六十甲子表[new干]
          ? 六十甲子表[new干]["leadStem"]
          : new干;
        const compare干resolved = 六十甲子表[this.selected年干outcome]
          ? 六十甲子表[this.selected年干outcome]["leadStem"]
          : this.selected年干outcome;
        this.asker = this.qimen.predict.interactions[new干resolved].me;
        this.interaction =
          this.qimen.predict.interactions[new干resolved].outcome[
            compare干resolved
          ];
        this.outcome = this.qimen.predict.interactions[compare干resolved].me;

        this.interactionScoreDisplay = this.interactionScoreDisplayText(this.interaction.score);

        // scores range from -8 to 8. 0 should be 0.5 mark
        if (this.interaction.score >= 0) {
          this.interactionScore = 0.5 + (this.interaction.score / 9) * 0.5;
        } else {
          this.interactionScore = ((9 + this.interaction.score) / 9) * 0.5;
        }
        this.redBorderStem = compare干resolved;
        this.blueBorderStem = new干resolved;
      } else if (this.predictionMode == "事业" && new干) {
        const new干resolved = 六十甲子表[new干]
          ? 六十甲子表[new干]["leadStem"]
          : new干;
        this.outcomeToggle = "manualOutcome";
        this.asker = this.qimen.predict.interactions[new干resolved].me;
        this.interaction =
          this.qimen.predict.interactions[new干resolved].outcome[
            this.qimen.predict.career
          ];
        this.outcome =
          this.qimen.predict.interactions[this.qimen.predict.career].me;

        this.interactionScoreDisplay = this.interactionScoreDisplayText(this.interaction.score);

        // scores range from -8 to 8. 0 should be 0.5 mark
        if (this.interaction.score >= 0) {
          this.interactionScore = 0.5 + (this.interaction.score / 9) * 0.5;
        } else {
          this.interactionScore = ((9 + this.interaction.score) / 9) * 0.5;
        }
        this.redBorderStem = this.qimen.predict.career;
        this.blueBorderStem = new干resolved;
      } else if (this.predictionMode == "生意" && new干) {
        const new干resolved = 六十甲子表[new干]
          ? 六十甲子表[new干]["leadStem"]
          : new干;
        this.outcomeToggle = "manualOutcome";
        this.asker = this.qimen.predict.interactions[new干resolved].me;
        this.interaction =
          this.qimen.predict.interactions[new干resolved].outcome[
            this.qimen.predict.business
          ];
        this.outcome =
          this.qimen.predict.interactions[this.qimen.predict.business].me;

        this.interactionScoreDisplay = this.interactionScoreDisplayText(this.interaction.score);

        // scores range from -8 to 8. 0 should be 0.5 mark
        if (this.interaction.score >= 0) {
          this.interactionScore = 0.5 + (this.interaction.score / 9) * 0.5;
        } else {
          this.interactionScore = ((9 + this.interaction.score) / 9) * 0.5;
        }
        this.redBorderStem = this.qimen.predict.business;
        this.blueBorderStem = new干resolved;
      }
    },
    selected年干outcome(new干) {
      if (this.predictionMode == "比较" && new干 && this.selected年干) {
        const new干resolved = 六十甲子表[this.selected年干]
          ? 六十甲子表[this.selected年干]["leadStem"]
          : this.selected年干;
        const compare干resolved = 六十甲子表[new干]
          ? 六十甲子表[new干]["leadStem"]
          : new干;
        this.asker = this.qimen.predict.interactions[new干resolved].me;
        this.interaction =
          this.qimen.predict.interactions[new干resolved].outcome[
            compare干resolved
          ];
        this.outcome = this.qimen.predict.interactions[compare干resolved].me;

        this.interactionScoreDisplay = this.interactionScoreDisplayText(this.interaction.score);

        // scores range from -8 to 8. 0 should be 0.5 mark
        if (this.interaction.score >= 0) {
          this.interactionScore = 0.5 + (this.interaction.score / 9) * 0.5;
        } else {
          this.interactionScore = ((9 + this.interaction.score) / 9) * 0.5;
        }
        this.redBorderStem = compare干resolved;
        this.blueBorderStem = new干resolved;
      }
    },
    sicknessPredictionType(type) {
      if (type == 'sickness') {
        this.blueBorderStem = null;
        this.sicknessDisplayText = this.qimen.predict.sicknessText
      } else if (type == 'doctor.chinese') {
        this.blueBorderStem = this.qimen.predict.doctor.chinese
        this.sicknessDisplayText = this.qimen.predict.doctorOutcome.chinese
      } else if (type == 'doctor.western') {
        this.blueBorderStem = this.qimen.predict.doctor.western
        this.sicknessDisplayText = this.qimen.predict.doctorOutcome.western
      } else {
        this.blueBorderStem = null;
        this.sicknessDisplayText = null;
      }
    }
  },
  computed: {
    timeString() {
      return this.qimen
        ? DateTime.fromISO(
            this.qimen.queryStringParameters.time
          ).toLocaleString(DateTime.DATETIME_MED)
        : "";
    },
    hourOutcome() {
      if (this.qimen) {
        return this.getPalacePos(this.qimen.predict.outcome);
      }
      return -1;
    },
    interactionScoreColor() {
      return this.computeColor(this.interactionScore);
    },
    validDate() {
      if (this.year && this.month && this.day) {
        return DateTime.fromObject({
          year: this.year,
          month: this.month,
          day: this.day,
        }).isValid;
      }
      return true;
    },
  },
  mounted() {
    this.getPredict('now');
    let tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
    hourlyModal = new Modal(document.getElementById('exampleModal'))
  },
  methods: {
    async getPredict(time) {      
      //console.log(time)
      const apiName = "qimenpredict";
      const path = "/predict";
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        // 盘伏吟/反吟??
        //queryStringParameters: { time: "2022-04-25T13:49" },
        //queryStringParameters: { time: "2025-05-22T09:49" },
        //queryStringParameters: { time: "2022-05-03T06:07" },
        queryStringParameters: { time: time == 'now' ? DateTime.now().toISO().split('.')[0] : (time.includes(".") ? time.split('.')[0] : time) }
      };
      try {        
        this.predictionMode = null;
        this.qimen = await API.get(apiName, path, myInit);     
        let timestamp = DateTime.fromISO(this.qimen.queryStringParameters.time)
        this.day = timestamp.day
        this.month = timestamp.month
        this.year = timestamp.year
        this.hour = parseInt(timestamp.toFormat('h'))
        this.minute = timestamp.toFormat('mm')
        this.selectedTimeOfDay = timestamp.toFormat('a')
        this.predictionMode = "基本";
      } catch (e) {
        console.log(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
        this.$router.push("/");
      }
    },
    async getPredictStocks() {
      const apiName = "qimenpredict";
      const path = "/predict/stocks";
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        // 盘伏吟/反吟??
        //queryStringParameters: { time: "2022-04-25T13:49" },
        //queryStringParameters: { time: "2025-05-22T09:49" },
        //queryStringParameters: { time: "2022-11-25T19:49" },
        queryStringParameters: { time: DateTime.now().toISO().split('.')[0], 年干: this.selected年干, givenNumber: this.givenNumber }
      };
      try {
        this.stocksStatus = 'loading'
        this.stocksOutcome = await API.get(apiName, path, myInit);
        this.qimen = this.stocksOutcome;
        this.stocksStatus = 'results';
        this.blueBorderStem = 六十甲子表[this.stocksOutcome.predict.stocks.asker] ? 六十甲子表[this.stocksOutcome.predict.stocks.asker]['leadStem'] : this.stocksOutcome.predict.stocks.asker;
      } catch (e) {
        alert(e.message);
        this.stocksStatus = 'new'
        this.givenNumber = null;
        this.selected年干 = ''
        this.stocksOutcome = null;
        this.blueBorderStem = ''
      }
    },
    async processHourlyInput() {
      if (!this.validDate) {
        alert("请输入有效日期与时间");
        return;
      }
      if (!this.year || !this.month || !this.day) {
        return;
      }
      if (!this.hour || !this.minute || !this.selectedTimeOfDay) {
        return;
      }
      let hour = this.selectedTimeOfDay == "AM" ? this.hour : this.hour + 12;
      hour = hour == 12 ? "0" : hour;
      hour = hour == 24 ? "12" : hour;
      hour = String(hour).padStart(2, "0");

      const birthday = `${this.year}-${String(this.month).padStart(
        2,
        "0"
      )}-${String(this.day).padStart(2, "0")}T${hour}:${this.minute}`;      
      this.qimen = null;
      hourlyModal.hide();
      await this.getPredict(birthday);
    },
    redoPredictStocks() {
        this.stocksStatus = 'new'
        this.givenNumber = null;
        this.selected年干 = ''
        this.stocksOutcome = null;
        this.blueBorderStem = ''
    },
    getPredictRandom() {
        if (!this.selected年干) {
          alert('请选择年干')
          return;
        }
        if (!this.givenNumber) {
          alert('请输入数目')
          return;
        }
        if (!toNumber(this.givenNumber)) {
          alert('请输入数目')
          return;
        }
        if (toNumber(this.givenNumber) < 0) {
          alert('请输入正数')
          return;
        }
        if (!isInteger(toNumber(this.givenNumber))) {
          alert('请输入整数')
          return;
        }

        let targetPalace = this.givenNumber % 9;
        if (targetPalace == 5) {
          this.randomPickStatus = 'error'
        } else {
          if (targetPalace == 0) targetPalace = 9; // 2023 change

          this.randomPickStatus = 'results'
          targetPalace = 洛书.indexOf(targetPalace)
          const compare干resolved = this.qimen.奇门时盘.天盘[targetPalace]

          const new干resolved = 六十甲子表[this.selected年干]
            ? 六十甲子表[this.selected年干]["leadStem"]
            : this.selected年干;
          this.asker = this.qimen.predict.interactions[new干resolved].me;
          this.interaction =
            this.qimen.predict.interactions[new干resolved].outcome[
              compare干resolved
            ];
          this.outcome = this.qimen.predict.interactions[compare干resolved].me;

          this.interactionScoreDisplay = this.interactionScoreDisplayText(this.interaction.score);

          // scores range from -8 to 8. 0 should be 0.5 mark
          if (this.interaction.score >= 0) {
            this.interactionScore = 0.5 + (this.interaction.score / 9) * 0.5;
          } else {
            this.interactionScore = ((9 + this.interaction.score) / 9) * 0.5;
          }
          this.redBorderStem = compare干resolved;
          this.blueBorderStem = new干resolved;
        }
    },
    redoPredictRandom() {
        this.randomPickStatus = 'new'
        this.givenNumber = null;
        this.selected年干 = ''
        this.blueBorderStem = ''
        this.redBorderStem = ''
    },
    getPalacePos(stem) {
      if (this.qimen) {
        const stemLocation = this.qimen.奇门时盘.天盘.indexOf(stem);
        return stemLocation == -1
          ? this.qimen.奇门时盘.天盘left.indexOf(stem)
          : stemLocation;
      }
      return -1;
    },
    redBorderMe(pos) {
      if (this.outcomeToggle == "hourOutcome") {
        if (pos == this.hourOutcome) {
          return true;
        }
      } else if (pos == this.getPalacePos(this.redBorderStem)) {
        return true;
      }
      return false;
    },
    blueBorderMe(pos) {
      if (pos == this.getPalacePos(this.blueBorderStem)) {
        return true;
      }
      return false;
    },
    blueBorderMeTwo(pos) {
      if (pos == this.getPalacePos(this.blueBorderStemTwo)) {
        return true;
      }
      return false;
    },
    interactionBorder(value) {
      const scores = {
        我生: "cn bg-primary text-white",
        我克: "cn bg-warning text-dark",
        克我: "cn bg-danger text-white",
        生我: "cn bg-success text-white",
        平: "cn bg-success text-white",
      };
      return scores[value];
    },
    computeColor(score) {
      if (score >= 0.1875 && score < 0.5625) {
            return "bg-warning";
          } else if (score < 0.1875) {
            return "bg-danger";
          } else {
            return "bg-success";
          }
    },
    calendar(pos) {
      return [
        '4-5',
        '6',
        '7-8',
        '3',
        '',
        '9',
        '1-2',
        '12',
        '10-11'
      ][pos]
    },
    dispDateString(date) {
      return DateTime.fromISO(
            date
          ).toLocaleString(DateTime.DATETIME_MED)
    },
    async goPrevHour() {
      const timeString = this.qimen.queryStringParameters.time.includes(".") ? this.qimen.queryStringParameters.time.split(".")[0] : this.qimen.queryStringParameters.time
      this.qimen = null;
      await this.getPredict(DateTime.fromISO(timeString).minus({ 'hours': 2 }).toISO())     
    },
    async goNextHour() {
      const timeString = this.qimen.queryStringParameters.time.includes(".") ? this.qimen.queryStringParameters.time.split(".")[0] : this.qimen.queryStringParameters.time
      this.qimen = null;
      await this.getPredict(DateTime.fromISO(timeString).plus({ 'hours': 2 }).toISO())      
    },
    interactionScoreDisplayText(score) {
      switch (score) {
        case -8:
        case -7:
        case -6:        
          return '凶';
        case -5:
        case -4:
        case -3:
        case -2:
          return '小凶';
        case -1:
        case 0:
        case 1:
          return '平';
        case 2:
        case 3:
        case 4:
        case 5:
          return '小吉';
        case 6:
        case 7:
        case 8:
          return '吉';
        default:
          return '';
      }
    },
    refreshTime() {
      this.qimen = null;
      this.getPredict('now');
    },
    getPredictEducation() {
      if (!this.educationPredictionType) {
        alert('请选择预测')
        return;
      }
      if (!this.educationSelectedAskerType) {
        alert('请选择测法')
        return;
      }
      if (this.educationSelectedAskerType == 'select' && !this.educationSelected年干) {
        alert('请选择年干')
        return;
      }

      let rawAsker = "";
      if (this.educationSelectedAskerType == 'select') {
        rawAsker = this.educationSelected年干
      } else {
        rawAsker = this.qimen.predict.outcome
      }
      const asker = 六十甲子表[rawAsker]
          ? 六十甲子表[rawAsker]["leadStem"]
          : rawAsker;
      this.blueBorderStem = asker;

      if (this.educationPredictionType == '考试') {
        const examPaperResult = this.qimen.predict.interactions[this.qimen.predict.education.exam.examPaper].outcome[asker]['宫']
        const examSubmissionResult = this.qimen.predict.interactions[this.qimen.predict.education.exam.examSubmission].outcome[asker]['宫']
        
        if ((examPaperResult == '我生' || examPaperResult == '平') && (examSubmissionResult == '我生' || examSubmissionResult == '平')) {
          this.educationOutcomeText = '成绩好'
        } else if (examPaperResult == '我克' && examSubmissionResult == '我克') {
          this.educationOutcomeText = '成绩不理想'
        } else {
          this.educationOutcomeText = '成绩一般'
        }
      } else if (this.educationPredictionType == '升学') {
        const educatorResult = this.qimen.predict.interactions[this.qimen.predict.education.school.educator].outcome[asker]['宫']
        const institutionResult = this.qimen.predict.interactions[this.qimen.predict.education.school.institution].outcome[asker]['宫']
        const schoolPriorityResult = this.qimen.predict.interactions[this.qimen.predict.education.school.schoolPriority].outcome[asker]['宫']

        if ((educatorResult == '我生' || educatorResult == '平') && (institutionResult == '我生' || institutionResult == '平') && (schoolPriorityResult == '我生' || schoolPriorityResult == '平')) {
          this.educationOutcomeText = '考入理想学校'
        } else if (educatorResult == '我克' && institutionResult == '我克' && schoolPriorityResult == '我克') {
          this.educationOutcomeText = '名落孙山'
        } else {
          this.educationOutcomeText = '有机会'
        }

        const askerPos = this.qimen.奇门时盘.天盘.indexOf(asker) == -1 ? this.qimen.奇门时盘.天盘left.indexOf(asker) : this.qimen.奇门时盘.天盘.indexOf(asker)
        if (this.qimen.奇门时盘.空马[askerPos].includes('空')) {
          this.educationOutcomeText += ' 难考入'
        } else if (入墓[askerPos].includes(asker)) {
          this.educationOutcomeText += ' 难考入'
        }

        if (["4杜", "2死", "3伤", "7惊"].includes(this.qimen.奇门时盘.八门[askerPos])) {
          this.educationOutcomeText = '名落孙山'
        }
      }
    },
    redoPredictEducation() {
      this.blueBorderStem = "";
      this.educationPredictionType = "";
      this.educationSelectedAskerType = "";
      this.educationSelected年干 = "";
      this.educationOutcomeText = "";
    },
    palaceFormations(pos) {
      return this.qimen.formations.filter((f) => {
        return f['SK'].split("#")[1] == this.positions[pos]
      })
    }
  },
};
</script>

<style scoped>
.cn {
  font-family: adobe-kaiti-std, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #613914;
}
.en-l {
  font-family: alibaba-puhuiti, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.en-r {
  font-family: alibaba-puhuiti, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.kong {
  width: 1.25em;
  background-color: #ffee58;
}

.金 {
  background-color: #d9cb9e;
}
.木 {
  background-color: #1bbc9b;
}
.水 {
  background-color: #4cb2f7;
}
.火 {
  background-color: #ff8080;
}
.土 {
  background-color: #ebaf70;
}

.pink {
  background-color: #f9e7e6;
}
.light-yellow {
  background-color: #fefaea;
}
.brown-text {
  color: #613914;
}

.pillars {
  font-size: 1rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #613914;
}
.nav-link {
  color: #613914;
}
.nav-link:hover {
  color: #b46723;
}

.gauge {
  width: 100%;
  max-width: 100px;
  font-size: 1rem;
  color: #613914;
  font-family: alibaba-puhuiti, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.gauge__body {
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: #b4c0be;
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
}

.gauge__fill {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;
  /*background: rgba(255, 0, 0, 1);*/
  transform-origin: center top;
  transform: rotate(0.25turn);
  transition: transform 0.2s ease-out;
}

.gauge__cover {
  width: 75%;
  height: 150%;
  background: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);

  /* Text */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25%;
  box-sizing: border-box;
}
.gauge-label {
  font-size: 0.65em;
}

.btn-brown {
  color: #fff;
  background-color: #613914;
}
.btn-brown:hover {
  color: #fff;
  background-color: #b46723;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.tp-金 {
  width: 1.25em;
  background-color: #d9cb9e;
  color: #212529;
}
.tp-木 {
  width: 1.25em;
  background-color: #1bbc9b;
  color: #212529;
}
.tp-水 {
  width: 1.25em;
  background-color: #4cb2f7;
  color: #212529;
}
.tp-火 {
  width: 1.25em;
  background-color: #ff8080;
  color: #212529;
}
.tp-土 {
  width: 1.25em;
  background-color: #ebaf70;
  color: #212529;
}
.tooltip-inner {
  max-width: 36em !important;
}
</style>
