<template>
  <div class="container py-3" v-if="!qimen">
    <div class="d-flex align-items-center brown-text">
              <span class="en-r brown-text">计算中...</span>
              <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
  </div>
  <div class="container pt-3" v-if="qimen">
    <div class="d-flex pb-3" v-if="qimen ? strategy.tier == 'admin' : false">
      <button type="button" class="btn btn-secondary ms-auto" data-bs-toggle="modal" data-bs-target="#exampleModal"><i class="far fa-clock"></i> 选时</button>
    </div>
    <div class="d-flex pb-3">
      <!-- <a type="button" class="btn btn-warning mx-auto" href="#/"><i class="fas fa-video"></i> 奇门预测班视频</a> -->
      <a type="button" class="btn btn-warning mx-auto" href="#/qimen/predict"
        ><i class="fas fa-binoculars"></i> 奇门预测</a
      >
      <a type="button" class="btn btn-warning mx-auto" href="#/qimen/strategy"><i class="fas fa-chess"></i> 奇门运筹</a>
    </div>
    <div class="row justify-content-center align-items-center">
      <div class="col-6 col-md-4 col-lg-2 text-center">
        <div class="row">
          <div class="col en-r brown-text" style="font-size: 0.8rem">
            <img
        src="../assets/qimen_wealthbusiness.png"
        class="img-fluid"
      /><br />{{ timeString }}&nbsp;&nbsp;<a @click="getHourly"><i class="fas fa-redo"></i></a>
          </div>
        </div>
        <div class="row light-yellow g-0">
          <div class="col border"><span class="cn pillars">年</span></div>
          <div class="col border"><span class="cn pillars">月</span></div>
          <div class="col border"><span class="cn pillars">日</span></div>
          <div class="col border"><span class="cn pillars">时</span></div>
        </div>
        <div class="row g-0">
          <div class="col border border-bottom-0">
            <span class="cn pillars">{{ strategy.奇门能量.bazi.year[0] }}</span>
          </div>
          <div class="col border border-bottom-0">
            <span class="cn pillars">{{ strategy.奇门能量.bazi.month[0] }}</span>
          </div>
          <div class="col border border-bottom-0">
            <span class="cn pillars">{{ strategy.奇门能量.bazi.day[0] }}</span>
          </div>
          <div
            class="col border"
            :class="{
              'border-bottom-0': outcomeToggle != 'hourOutcome',
              'border-danger': outcomeToggle == 'hourOutcome',
            }"
          >
            <span class="cn pillars">{{ strategy.奇门能量.bazi.hour[0] }}</span>
          </div>
        </div>
        <div class="row g-0">
          <div class="col border border-top-0">
            <span class="cn pillars">{{ strategy.奇门能量.bazi.year[1] }}</span>
          </div>
          <div class="col border border-top-0">
            <span class="cn pillars">{{ strategy.奇门能量.bazi.month[1] }}</span>
          </div>
          <div class="col border border-top-0">
            <span class="cn pillars">{{ strategy.奇门能量.bazi.day[1] }}</span>
          </div>
          <div
            class="col border border-top-0"
            :class="{
              'border-danger':
                outcomeToggle == 'hourOutcome',
            }"
          >
            <span class="cn pillars">{{ strategy.奇门能量.bazi.hour[1] }}</span>
          </div>
        </div>
        <div class="row mt-2">
          <button v-if="predictionMode == '孤虚法'" type="button" class="col-6 btn btn-secondary mx-auto" data-bs-toggle="modal" data-bs-target="#chooseHour"><i class="far fa-clock"></i> 选时</button>
        </div>
      </div>
      <div class="col-12 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
        <ul
          class="nav nav-pills mb-3 justify-content-center pt-1"
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active px-3 px-sm-2 px-lg-1 py-1 en-r"
              id="pills-增运法-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-增运法"
              type="button"
              role="tab"
              @click="this.predictionMode = '增运法'"
            >
              奇门能量
            </button>
          </li>          
        </ul>
        <hr class="mt-0" />
        <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-增运法" role="tabpanel">
            <div class="d-flex flew-row align-items-center">
              <div class="col en-r brown-text text-center">个人奇门能量方位 ({{ strategy.奇门能量.start }} 至 {{ strategy.奇门能量.end }})
                <br/>{{ strategy.name }}<br/>
                {{ dispDateString(strategy.birthday) }}<br/>
              </div>
            </div>
          </div>
        </div>          
      </div>
    </div>
    <hr />    
    <div class="row justify-content-center pt-1 pb-4 gx-3 gy-3" v-if="predictionMode == '增运法'">
        <!-- <template v-for="(hours, date) in strategy.增运法" :key="date">
        <div class="col-12 col-md-4">
            <div class="row pb-2">
                <div class="col text-center brown-text">
                    <strong>{{ date }}</strong>
                </div>
            </div>
            <div class="row pb-3 brown-text">
                <div class="col-6">
                    <span v-for="n in 12" :key="n"><template v-if="hours[n-1] != '-'">
                      {{ hoursDisplay[n-1] }} AM: {{ hours[n-1][0].pos }} <i :class="hours[n-1][0].icon"></i>
                      <template v-if="hours[n-1][1]">
                        / {{ hours[n-1][1].pos }} <i :class="hours[n-1][1].icon"></i>
                      </template>
                      <template v-if="hours[n-1][2]">
                        / {{ hours[n-1][2].pos }} <i :class="hours[n-1][2].icon"></i>
                      </template>
                      <br/></template></span>
                </div>
                <div class="col-6">
                    <span v-for="n in 12" :key="n"><template v-if="hours[n-1+12] != '-'">
                      {{ hoursDisplay[n-1] }} PM: {{ hours[n-1+12][0].pos }} <i :class="hours[n-1+12][0].icon"></i>
                      <template v-if="hours[n-1+12][1]">
                        / {{ hours[n-1][1].pos }} <i :class="hours[n-1+12][1].icon"></i>
                      </template>
                      <template v-if="hours[n-1+12][2]">
                        / {{ hours[n-1][2].pos }} <i :class="hours[n-1+12][2].icon"></i>
                      </template>
                      <br/></template></span>
                </div>
                <div class="col-12 text-center brown-text" v-if='JSON.stringify(hours) == JSON.stringify([ "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-" ])'>
                    此日没有吉位
                </div>
            </div>            
        </div>
        </template>
        <div class="col-12 text-center brown-text">
            <i class="fas fa-briefcase"></i> 事业，提案 &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; <i class="fas fa-bed"></i> 治疗，休息，贵人 &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; <i class="fas fa-hand-holding-usd"></i> 生意，资金
        </div> -->
        <template v-for="(energy, energyPos) in ['财富','事业','健康','市场']" :key="energy">
          <div class="col-12 col-md-6">
            <h5 class="brown-text"><i :class="icons[energyPos]"></i>&nbsp;{{ energy }}能量</h5>
            <table class="table" v-if="strategy['奇门能量']['output'][energy]">
              <thead style="background-color: #613914;">
                <tr>
                  <td style="color: #fff;"><strong>日期 Date</strong></td>
                  <td style="color: #fff;"><strong>时间 Hour</strong></td>
                  <td style="color: #fff;"><strong>方向 Direction</strong></td>
                </tr>
              </thead>
              <tbody>
                <template v-for="(hour, hourIdx) in strategy['奇门能量']['output'][energy]" :key="hourIdx">
                  <tr>
                    <td>{{ hour.day }}</td>
                    <td>{{ String(hour.hour).padStart(2,'0') }}:00</td>
                    <td>{{ hour.pos }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
            <template v-else>
              <div class="pt-2 pb-4">没有方位</div>
            </template>
          </div>
        </template>
    </div>    
  </div>

  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-xl modal-fullscreen-xl-down
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Choose birthday
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form class="border px-3 py-3" novalidate>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label pt-0"
                >Date</label
              >
              <div class="col-sm-3">
                <select
                  class="form-select"
                  v-model="day"
                  :class="[
                    !day && turnOnValidators ? 'is-invalid' : '',
                    validDate ? '' : 'is-invalid',
                  ]"
                >
                  <option selected value="null">Select day 日</option>
                  <option
                    v-for="day in days"
                    :value="day.label"
                    :key="day.label"
                  >
                    {{ day.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <select
                  class="form-select"
                  v-model="month"
                  :class="[
                    !month && turnOnValidators ? 'is-invalid' : '',
                    validDate ? '' : 'is-invalid',
                  ]"
                >
                  <option selected value="null">Select month 月</option>
                  <option
                    v-for="month in months"
                    :value="month.value"
                    :key="month.value"
                  >
                    {{ month.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-4">
                <select
                  class="form-select"
                  v-model="year"
                  :class="[
                    !year && turnOnValidators ? 'is-invalid' : '',
                    validDate ? '' : 'is-invalid',
                  ]"
                >
                  <option selected value="null">Select year 年</option>
                  <option
                    v-for="year in years"
                    :value="year.label"
                    :key="year.label"
                  >
                    {{ year.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label pt-0"
                >Time</label
              >
              <div class="col-sm-3">
                <select
                  class="form-select"
                  v-model="hour"
                  :class="[
                    !hour && turnOnValidators,
                  ]"
                >
                  <option selected value="null">Select hour 时</option>
                  <option
                    v-for="hour in hours"
                    :value="hour.label"
                    :key="hour.label"
                  >
                    {{ hour.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <select
                  class="form-select"
                  v-model="minute"
                  :class="[
                    !minute && turnOnValidators,
                  ]"
                >
                  <option selected value="null">Select minute 分</option>
                  <option
                    v-for="minute in minutes"
                    :value="minute.label"
                    :key="minute.value"
                  >
                    {{ minute.label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-4">
                <select
                  class="form-select"
                  v-model="selectedTimeOfDay"
                  :class="[
                    !selectedTimeOfDay && turnOnValidators 
                  ]"
                >
                  <option selected value="null">Select AM/PM</option>
                  <option
                    v-for="t in timeOfDay"
                    :value="t.label"
                    :key="t.label"
                  >
                    {{ t.label }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="processHourlyInput">
            Generate
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import QimenPalace from "@/components/QimenPalace.vue";
import { Auth, API } from "aws-amplify";
import { DateTime } from "luxon";
// import { toNumber, isInteger } from "lodash";
import { Modal } from "bootstrap";
// import { Tooltip, Modal, Tab } from "bootstrap";

const inclusiveRange = (start, end, step) => {
  return Array.from(
    Array.from(Array(Math.ceil((end - start + 1) / step)).keys()),
    (x) => {
      return { label: start + x * step };
    }
  );
};

let hourlyModal = null;

export default {
  name: "QimenStrategy",
  components: {
    // QimenPalace,
  },
  data: () => ({
    strategy: null,
    qimen: null,
    outcomeToggle: "manual",
    positions: ["SE", "S", "SW", "E", "C", "W", "NE", "N", "NW"],
    hoursDisplay: [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    predictionMode: "",

    selectedDate: null,
    // asker: null,
    // interaction: null,
    // interactionScore: 0.5,
    // interactionScoreDisplay: 0,
    // outcome: null,
    // selected年干: "",
    // selected年干outcome: "",
    redBorderStem: "",
    blueBorderStem: "",
    blueBorderStemTwo: "",
    // marriage: {},
    // givenNumber: null,
    // stocksStatus: 'new', // new, loading, results
    // stocksOutcome: null,
    // marriageSelectedGender: "",
    // randomPickStatus: 'new', // new, loading, results, error
    // sicknessPredictionType: "",
    // sicknessDisplayText: "",
    // educationPredictionType: "", // results考试, school升学
    // educationSelectedAskerType: "", // own selection, hour stem
    // educationSelected年干: "",
    // educationOutcomeText: "",
    days: Array.from(Array(31).keys(), (n) => {
      return { label: n + 1 };
    }),
    months: [
      { label: "Jan (1)", value: 1 },
      { label: "Feb (2)", value: 2 },
      { label: "Mar (3)", value: 3 },
      { label: "Apr (4)", value: 4 },
      { label: "May (5)", value: 5 },
      { label: "Jun (6)", value: 6 },
      { label: "Jul (7)", value: 7 },
      { label: "Aug (8)", value: 8 },
      { label: "Sep (9)", value: 9 },
      { label: "Oct (10)", value: 10 },
      { label: "Nov (11)", value: 11 },
      { label: "Dec (12)", value: 12 },
    ],
    years: inclusiveRange(2025, 1918, -1),
    hours: Array.from(Array(12).keys(), (n) => {
      return { label: n + 1 };
    }),
    minutes: Array.from(Array(60).keys(), (n) => {
      return { label: String(n).padStart(2, "0") };
    }),
    timeOfDay: Array.from(["AM", "PM"], (n) => {
      return { label: n };
    }),
    day: null,
    month: null,
    year: null,
    hour: null,
    minute: null,
    selectedTimeOfDay: null,
    timestamp: null,
    turnOnValidators: true,
    selected年干: null,
    hourlySelected年干: null,
    icons: ['fas fa-hand-holding-usd','fas fa-briefcase','fas fa-heartbeat','fas fa-project-diagram']
  }),
  watch: {
    
  },
  computed: {
    timeString() {
      return this.qimen
        ? DateTime.fromISO(
            this.strategy.birthday
          ).toLocaleString(DateTime.DATETIME_MED)
        : "";
    },
    // hourOutcome() {
    //   if (this.qimen) {
    //     return this.getPalacePos(this.qimen.predict.outcome);
    //   }
    //   return -1;
    // },
    // interactionScoreColor() {
    //   return this.computeColor(this.interactionScore);
    // },
    validDate() {
      if (this.year && this.month && this.day) {
        const userDateInput = DateTime.fromObject({
          year: this.year,
          month: this.month,
          day: this.day,
        })
        return userDateInput.isValid
      }
      return true;
    },
    validDateOptions() {
      return [...Array(3).keys()].map(day => {
        return DateTime.now().startOf('day').plus({days:day}).toISODate()
      })
    }
  },
  mounted() {
    var self = this;
    self.getStrategy('now')

    hourlyModal = new Modal(document.getElementById('exampleModal'))
  },
  methods: {
    async getStrategy() {
      const apiName = "qimenstrategy";
      const path = "/wealth2024";
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        queryStringParameters: { time: DateTime.now().toISO().split('.')[0] }
      };
      try {        
        this.strategy = await API.get(apiName, path, myInit);
        this.qimen = JSON.parse(this.strategy.qimen);
        this.predictionMode = '增运法'
      } catch (e) {
        console.log(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
        this.$router.push("/");
      }
    },
    dispDateString(date) {
      return DateTime.fromISO(
            date
          ).toLocaleString(DateTime.DATETIME_MED)
    },
    refreshTime() {
      this.qimen = null;
      this.getStrategy('now');
    },
    dayOfWeek(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
    },
    async processHourlyInput() {
      if (!this.validDate) {
        alert("请输入有效日期与时间");
        return;
      }
      if (!this.year || !this.month || !this.day) {
        return;
      }
      if (!this.hour || !this.minute || !this.selectedTimeOfDay) {
        return;
      }
      let hour = this.selectedTimeOfDay == "AM" ? this.hour : this.hour + 12;
      hour = hour == 12 ? "0" : hour;
      hour = hour == 24 ? "12" : hour;
      hour = String(hour).padStart(2, "0");

      const birthday = `${this.year}-${String(this.month).padStart(
        2,
        "0"
      )}-${String(this.day).padStart(2, "0")}T${hour}:${this.minute}`;      
      this.qimen = null;
      hourlyModal.hide();

      const apiName = "qimenstrategy";
      const path = "/wealth2024/custom";
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        queryStringParameters: { Birthday: birthday }
      };
      try {        
        this.strategy = await API.get(apiName, path, myInit);
        this.qimen = JSON.parse(this.strategy.qimen);
        this.predictionMode = '增运法'
      } catch (e) {
        console.log(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
        this.$router.push("/");
      }
    },
  },
};
</script>

<style scoped>
.cn {
  font-family: adobe-kaiti-std, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #613914;
}
.en-l {
  font-family: alibaba-puhuiti, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.en-r {
  font-family: alibaba-puhuiti, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.kong {
  width: 1.25em;
  background-color: #ffee58;
}

.金 {
  background-color: #d9cb9e;
}
.木 {
  background-color: #1bbc9b;
}
.水 {
  background-color: #4cb2f7;
}
.火 {
  background-color: #ff8080;
}
.土 {
  background-color: #ebaf70;
}

.pink {
  background-color: #f9e7e6;
}
.light-yellow {
  background-color: #fefaea;
}
.brown-text {
  color: #613914;
}

.pillars {
  font-size: 1rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #613914;
}
.nav-link {
  color: #613914;
}
.nav-link:hover {
  color: #b46723;
}

.gauge {
  width: 100%;
  max-width: 100px;
  font-size: 1rem;
  color: #613914;
  font-family: alibaba-puhuiti, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.gauge__body {
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: #b4c0be;
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
}

.gauge__fill {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;
  /*background: rgba(255, 0, 0, 1);*/
  transform-origin: center top;
  transform: rotate(0.25turn);
  transition: transform 0.2s ease-out;
}

.gauge__cover {
  width: 75%;
  height: 150%;
  background: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);

  /* Text */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25%;
  box-sizing: border-box;
}
.gauge-label {
  font-size: 0.65em;
}

.btn-brown {
  color: #fff;
  background-color: #613914;
}
.btn-brown:hover {
  color: #fff;
  background-color: #b46723;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.tp-金 {
  width: 1.25em;
  background-color: #d9cb9e;
  color: #212529;
}
.tp-木 {
  width: 1.25em;
  background-color: #1bbc9b;
  color: #212529;
}
.tp-水 {
  width: 1.25em;
  background-color: #4cb2f7;
  color: #212529;
}
.tp-火 {
  width: 1.25em;
  background-color: #ff8080;
  color: #212529;
}
.tp-土 {
  width: 1.25em;
  background-color: #ebaf70;
  color: #212529;
}
.tooltip-inner {
  max-width: 36em !important;
}
</style>
