<template>
  <div class="container my-5">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center" v-if='!pdfLink'>
          <div class="spinner-border text-danger" role="status"></div>
          <div class="ms-3">
            <strong>Loading file...</strong>
          </div>
        </div>
        <div class="d-flex align-items-center" v-if='pdfLink'>
          <button type="button" class="btn btn-outline-dark" @click="goBack"><i class="fas fa-chevron-left"></i> Back to Content Hub</button>          
          &nbsp;&nbsp;<button type="button" class="btn btn-outline-dark" @click="downloadFile" v-bind:disabled='downloadInProgress'><i class="fas fa-file-download" v-if='!downloadInProgress'></i><i class="fas fa-spinner fa-spin" v-if='downloadInProgress'></i> Download file</button>
          &nbsp;&nbsp;<button type="button" class="btn btn-outline-dark" data-bs-toggle="modal" data-bs-target="#exampleModal" v-if="this.currentCalendar?.CalendarICSData"><i class="fas fa-file-download"></i> Add to calendar</button>
          &nbsp;&nbsp;<p class="mb-0">Tip: to print, click Download file then print using your PDF viewer.<br/>Click on each day to view 五不遇时 and 天显时刻.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-between py-2" v-if='pdfLink'>
      <div class="col-auto">
        <button type="button" class="btn btn-warning" @click="goPrevMonth" v-if='hasPrevMonth'><i class="fas fa-backward"></i> Previous Month</button>
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-warning" @click="goNextMonth" v-if='hasNextMonth'>Next Month <i class="fas fa-forward"></i></button>
      </div>
    </div>
    <!-- <div class="row justify-content-center" v-if='pdfLink'>
      <div class="col-12 col-lg-6">
        <a href='https://jhmetaphysics.com/qm-workshop-2023-mar' target='_blank'>
        <img src="../assets/2023qimen-march.png"
        class="img-fluid" />
        </a>
      </div>
    </div> -->
    <div class="row">
      <div class="col-12">
        <!-- <template v-if='pdfLink'>
          <VuePdfEmbed          
            :source='pdfLink'
            @loaded = "rendered_file"
            v-show="showFile"
          />
          <img :src="pdfLink" class="img-fluid" />          
        </template>   -->    
        <VuePdf
            v-if='pdfLink'
            :src='pdfLink'
          />  
      </div>
    </div>
    <div class="row justify-content-between" v-if='pdfLink'>
      <div class="col-auto">
        <button type="button" class="btn btn-warning" @click="goPrevMonth" v-if='hasPrevMonth'><i class="fas fa-backward"></i> Previous Month</button>
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-warning" @click="goNextMonth" v-if='hasNextMonth'>Next Month <i class="fas fa-forward"></i></button>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
  >
    <div
        class="
        modal-dialog modal-dialog-centered modal-xl modal-fullscreen-xl-down
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Add to Calendar
          </h5>
          <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="clearData"
          ></button>
        </div>
        <div class="modal-body">
          <form class="border px-3 py-3" novalidate>
            <h5>每日时辰&nbsp;&nbsp;&nbsp;<button
                type="button"
                class="btn btn-link btn-sm"
                @click="selectAllBySection('每日时辰')"
            >
              Select all
            </button></h5>
            <div class="col-sm-12 mb-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="天显时刻"
                    id="天显时刻"
                    v-model="icalSelections"
                />
                <label class="form-check-label pt-0" for="天显时刻">
                  天显时刻
                </label>
              </div>
            </div>
            <div class="col-sm-12 mb-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="五不遇时"
                    id="五不遇时"
                    v-model="icalSelections"
                />
                <label class="form-check-label pt-0" for="五不遇时">
                  五不遇时
                </label>
              </div>
            </div>
            <h5>合克日&nbsp;&nbsp;&nbsp;<button
                type="button"
                class="btn btn-link btn-sm"
                @click="selectAllBySection('合克日')"
            >
              Select all
            </button></h5>
            <div class="col-sm-12 mb-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="合克#年"
                    id="合克#年"
                    v-model="icalSelections"
                />
                <label class="form-check-label pt-0" for="合克#年">
                  健康，市场
                </label>
              </div>
            </div>
            <div class="col-sm-12 mb-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="合克#月"
                    id="合克#月"
                    v-model="icalSelections"
                />
                <label class="form-check-label pt-0" for="合克#月">
                  事业，商业
                </label>
              </div>
            </div>
            <div class="col-sm-12 mb-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="合克#日"
                    id="合克#日"
                    v-model="icalSelections"
                />
                <label class="form-check-label pt-0" for="合克#日">
                  个人，夫妻
                </label>
              </div>
            </div>
            <div class="col-sm-12 mb-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="合克#时"
                    id="合克#时"
                    v-model="icalSelections"
                />
                <label class="form-check-label pt-0" for="合克#时">
                  理想，投资
                </label>
              </div>
            </div>
            <div class="col-sm-12 mb-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="合克#运"
                    id="合克#运"
                    v-model="icalSelections"
                />
                <label class="form-check-label pt-0" for="合克#运">
                  外在机会
                </label>
              </div>
            </div>
            <h5>特别日子&nbsp;&nbsp;&nbsp;<button
                type="button"
                class="btn btn-link btn-sm"
                @click="selectAllBySection('特别日子')"
            >
              Select all
            </button></h5>
            <div class="col-sm-12 mb-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="合克#福库"
                    id="合克#福库"
                    v-model="icalSelections"
                />
                <label class="form-check-label pt-0" for="合克#福库">
                  福库日
                </label>
              </div>
            </div>
            <div class="col-sm-12 mb-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="合克#福禄"
                    id="合克#福禄"
                    v-model="icalSelections"
                />
                <label class="form-check-label pt-0" for="合克#福禄">
                  福禄日
                </label>
              </div>
            </div>
            <div class="col-sm-12 mb-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="合克#血煞"
                    id="合克#血煞"
                    v-model="icalSelections"
                />
                <label class="form-check-label pt-0" for="合克#血煞">
                  血煞日
                </label>
              </div>
            </div>
            <div class="col-sm-12 mb-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="luckChange"
                    id="luckChange"
                    v-model="icalSelections"
                />
                <label class="form-check-label pt-0" for="luckChange">
                  交大运日（一年一次，不是每个月）
                </label>
              </div>
            </div>
            <div class="col-sm-12 mb-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="天赦日"
                    id="天赦日"
                    v-model="icalSelections"
                />
                <label class="form-check-label pt-0" for="天赦日">
                  天赦日（一年几次，不是每个月）
                </label>
              </div>
            </div>
            <h5>奇门吉位&nbsp;&nbsp;&nbsp;<button
                type="button"
                class="btn btn-link btn-sm"
                @click="selectAllBySection('奇门吉位')"
            >
              Select all
            </button></h5>
            <div class="col-sm-12 mb-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="奇门#值符"
                    id="奇门#值符"
                    v-model="icalSelections"
                />
                <label class="form-check-label pt-0" for="奇门#值符">
                  值符
                </label>
              </div>
            </div>
            <div class="col-sm-12 mb-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="奇门#财富"
                    id="奇门#财富"
                    v-model="icalSelections"
                />
                <label class="form-check-label pt-0" for="奇门#财富">
                  财富
                </label>
              </div>
            </div>
            <div class="col-sm-12 mb-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="奇门#职场"
                    id="奇门#职场"
                    v-model="icalSelections"
                />
                <label class="form-check-label pt-0" for="奇门#职场">
                  职场
                </label>
              </div>
            </div>
            <div class="col-sm-12 mb-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="奇门#健康"
                    id="奇门#健康"
                    v-model="icalSelections"
                />
                <label class="form-check-label pt-0" for="奇门#健康">
                  健康，贵人
                </label>
              </div>
            </div>
            <div class="col-sm-12 mb-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    value="奇门#许愿"
                    id="奇门#许愿"
                    v-model="icalSelections"
                />
                <label class="form-check-label pt-0" for="奇门#许愿">
                  许愿
                </label>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
              type="button"
              class="btn btn-secondary"
              @click="selectAll"
          >
            Select all options
          </button>
          <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="clearData"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary" @click="downloadICal">
            Add to calendar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { Auth, API } from "aws-amplify";
import { onBeforeRouteUpdate } from 'vue-router'
import * as ics from "ics";
import {each,flatten} from 'lodash';

export default {
  name: "Calendar",
  components: { },
  data: () => ({
    pdfLink: null,
    downloadInProgress: false,
    pdfStaticLink: null,
    showFile: false,
    icalSelections: [],
  }),
  watch: {},
  computed: {
    currentPos() {
      return this.$store.state.calendar.findIndex(e => e.S3ObjectKey == `${encodeURIComponent(this.$route.params.email)}/${this.$route.params.key}`)
    },
    hasPrevMonth() {
      return this.$store.state.calendar[this.currentPos - 1]
    },
    hasNextMonth() {
      return this.$store.state.calendar[this.currentPos + 1]
    },
    currentCalendar() {
      return this.$store.state.calendar[this.currentPos]
    }
  },
  setup() {
    onBeforeRouteUpdate((to) => {
      if (!to.params['key']) {
        let message = JSON.parse(to.params['email'])
        alert(`${message[0]}\n${message[1]}\n${message[2]}`)
        return false;
      }
    })
  },
  mounted() {
    this.getSignedLink(true);
    this.getSignedLinkStatic();
  },
  methods: {
    rendered_file() {
      this.showFile = true;
    },
    async getSignedLink(web = false, key = null, email = null) {
      this.pdfLink = null;
      const apiName = "links";
      const path = "/private";
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        queryStringParameters: { 'key': `${email ? email : encodeURIComponent(this.$route.params.email)}/${web ? ((key ? key.split(".")[0] : this.$route.params.key.split(".")[0]) + "_web.pdf") : (key ? key : this.$route.params.key)}`, itemType: 'calendar' },
        //queryStringParameters: { 'key': `${email ? email : encodeURIComponent(this.$route.params.email)}/${web ? ((key ? key.split(".")[0] : this.$route.params.key.split(".")[0]) + ".png") : (key ? key : this.$route.params.key)}`, itemType: 'calendar' },
      };
      try {
        /* const link = await API.get(apiName, path, myInit);
        const response = await fetch(link);
        this.pdfLink = await response.arrayBuffer() */
        this.pdfLink = await API.get(apiName, path, myInit);
      } catch (e) {
        console.log(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
        this.$router.push('/')
      }
    },
    async getSignedLinkStatic(web = false, key = null, email = null) {
      this.pdfStaticLink = null;
      const apiName = "links";
      const path = "/private";
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        queryStringParameters: { 'key': `${email ? email : encodeURIComponent(this.$route.params.email)}/${web ? ((key ? key.split(".")[0] : this.$route.params.key.split(".")[0]) + "_web.pdf") : (key ? key : this.$route.params.key)}`, itemType: 'calendar' },
      };
      try {
        this.pdfStaticLink = await API.get(apiName, path, myInit);
      } catch (e) {
        console.log(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
        this.$router.push('/')
      }
    },
    goBack() {
      this.$router.push('/')
    },
    async downloadFile() {
      this.downloadInProgress = true;
      await fetch(this.pdfStaticLink)
      .then(res => res.blob())
      .then(blob => saveAs(blob, `JHMetaphysics_${this.$route.params.key}`))
      .then(() => this.downloadInProgress = false )
    },
    goPrevMonth() {
      const month = this.$store.state.calendar[this.currentPos - 1]
      this.$router.push(`/calendar/${month.S3ObjectKey}`);
      this.getSignedLink(true, month.S3ObjectKey.split('/')[1], month.S3ObjectKey.split('/')[0])
      this.getSignedLinkStatic(false, month.S3ObjectKey.split('/')[1], month.S3ObjectKey.split('/')[0])
    },
    goNextMonth() {
      const month = this.$store.state.calendar[this.currentPos + 1]
      this.$router.push(`/calendar/${month.S3ObjectKey}`);
      this.getSignedLink(true, month.S3ObjectKey.split('/')[1], month.S3ObjectKey.split('/')[0])
      this.getSignedLinkStatic(false, month.S3ObjectKey.split('/')[1], month.S3ObjectKey.split('/')[0])
    },
    downloadICal() {
      try {
        const calendarData = this.$store.state.calendar[this.currentPos];
        const icalData = JSON.parse(calendarData?.CalendarICSData);
        const output = [];
        each(this.icalSelections, (selection) => {
          if (selection.includes("#")) {
            const [type, value] = selection.split("#");
            output.push(icalData?.[type]?.[value]);
          } else {
            if (icalData?.[selection]?.length > 0) {
              output.push(icalData?.[selection]);
            }
          }
        })

        const { error, value } = ics.createEvents(flatten(output))

        if (error) {
          console.log(error)
          alert(
              "An error has occurred. Please refresh the page and try again later."
          );
          this.icalSelections = [];
          return
        }

        const blob = new Blob([value], { type: 'text/calendar' });
        saveAs(blob, `JHMetaphysics_${this.$route.params.key.split(".")[0]}.ics`);
      } catch (e) {
        console.log(e)
        alert(
            "An error has occurred. Please refresh the page and try again later."
        );
        this.icalSelections = [];
      }
    },
    clearData() {
      this.icalSelections = [];
    },
    selectAllBySection(type){
      if (type === '每日时辰') {
        if (!this.icalSelections.includes('天显时刻')) {
          this.icalSelections.push('天显时刻');
        }
        if (!this.icalSelections.includes('五不遇时')) {
          this.icalSelections.push('五不遇时');
        }
      } else if (type === '合克日') {
        if (!this.icalSelections.includes('合克#年')) {
          this.icalSelections.push('合克#年');
        }
        if (!this.icalSelections.includes('合克#月')) {
          this.icalSelections.push('合克#月');
        }
        if (!this.icalSelections.includes('合克#日')) {
          this.icalSelections.push('合克#日');
        }
        if (!this.icalSelections.includes('合克#时')) {
          this.icalSelections.push('合克#时');
        }
        if (!this.icalSelections.includes('合克#运')) {
          this.icalSelections.push('合克#运');
        }
      } else if (type === '特别日子') {
        if (!this.icalSelections.includes('合克#福库')) {
          this.icalSelections.push('合克#福库');
        }
        if (!this.icalSelections.includes('合克#福禄')) {
          this.icalSelections.push('合克#福禄');
        }
        if (!this.icalSelections.includes('合克#血煞')) {
          this.icalSelections.push('合克#血煞');
        }
        if (!this.icalSelections.includes('luckChange')) {
          this.icalSelections.push('luckChange');
        }
        if (!this.icalSelections.includes('天赦日')) {
          this.icalSelections.push('天赦日');
        }
      } else if (type === '奇门吉位') {
        if (!this.icalSelections.includes('奇门#值符')) {
          this.icalSelections.push('奇门#值符');
        }
        if (!this.icalSelections.includes('奇门#财富')) {
          this.icalSelections.push('奇门#财富');
        }
        if (!this.icalSelections.includes('奇门#职场')) {
          this.icalSelections.push('奇门#职场');
        }
        if (!this.icalSelections.includes('奇门#健康')) {
          this.icalSelections.push('奇门#健康');
        }
        if (!this.icalSelections.includes('奇门#许愿')) {
          this.icalSelections.push('奇门#许愿');
        }
      }
    },
    selectAll() {
      this.icalSelections = ['天显时刻','五不遇时','合克#年','合克#月','合克#日','合克#时','合克#运','合克#福库','合克#福禄','合克#血煞','奇门#值符','奇门#财富','奇门#职场','奇门#健康','奇门#许愿', 'luckChange', '天赦日'];
    }
  },
};
</script>

<style scoped>
@media (max-width: 991.98px) {
  .custom-mobile-text {
    font-size: 0.9rem;
  }
  .page-header {
    margin-top: -8px;
  }
}

.services-container {
  color: #939597;
}
.services-container h5.card-title {
  color: #75787b;
}
.services-container h5.card-title-blue {
  color: #003a70;
}
.services-container h5.date {
  color: #939597;
  font-size: 0.85rem;
}
.services-container .card-text {
  font-size: 0.8rem;
}
.services-container h5 {
  font-size: 1.1rem;
}
</style>
